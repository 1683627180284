import React from 'react'
import HorizontalMenuItem from 'common/components/entities/Menu/components/Desktop/Horizontal/components/HorizontalMenuItem'
import HorizontalMenuUi from 'common/components/entities/Menu/components/Desktop/Horizontal/ui/HorizontalMenuUi'
import { MenuItemInterface } from 'common/components/entities/Menu/types/MenuInterface'
import { AlignSelfType, PaddingType } from 'common/types/styleTypes'
import { isBlogReplacementPlaceholder } from 'common/utils/blogReplacementsUtils'
import { FontProps } from 'common/utils/styleUtilsNew'

export interface HorizontalMenuProps extends FontProps {
  attrId: string
  items: MenuItemInterface[]
  alignSelf: AlignSelfType
  mobileAlignSelf?: AlignSelfType
  padding: PaddingType
  mobilePadding: PaddingType
  spacing: number
  mobileSpacing?: number
  subMenuColor?: string
  subMenuSpacing?: number
  lineHeight?: number
  mobileLineHeight?: number
  activeItemColor: string
  mobileActiveItemColor?: string
  itemUnderlineColor?: string
  mobileItemUnderlineColor?: string
  isVisibleOnMobile: boolean
  disablePointerEvents?: boolean
  forceHide?: boolean
  isEditing?: boolean
}

function HorizontalMenu(props: HorizontalMenuProps) {
  const {
    items,
    alignSelf,
    mobileAlignSelf,
    attrId,
    disablePointerEvents,
    padding,
    mobilePadding,
    isVisibleOnMobile,
    forceHide,
  } = props
  return (
    <HorizontalMenuUi
      alignSelf={alignSelf}
      mobileAlignSelf={mobileAlignSelf}
      id={attrId}
      disablePointerEvents={disablePointerEvents}
      padding={padding}
      mobilePadding={mobilePadding}
      isVisibleOnMobile={isVisibleOnMobile}
      forceHide={forceHide}
    >
      {items
        .filter(item => !isBlogReplacementPlaceholder(item.link))
        .map((item, index) => (
          <HorizontalMenuItem
            key={`${attrId}-horizontal-item-${index}-${item.link || item.text}`}
            item={item}
            index={index}
            {...props}
          />
        ))}
    </HorizontalMenuUi>
  )
}

export default HorizontalMenu
