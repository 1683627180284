import React, { memo, useContext } from 'react'
import { ColumnInterface } from 'common/components/entities/Column/types/ColumnInterface'
import CommonRow from 'common/components/entities/Row'
import { RowInterface } from 'common/components/entities/Row/types/RowInterface'
import { CreateEntitiesContext } from 'publisher/app'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { useColumnEntities } from 'publisher/components/entities/Row/hooks/useColumnEntities'
import { useBackgroundImage } from 'publisher/hooks/useBackgroundImage'
import { useDelay } from 'publisher/hooks/useDelay'

interface RowProps {
  entity: RowInterface
}

const Row = ({ entity }: RowProps) => {
  const isVisible = useDelay(entity.delay)
  const createEntityElement = useContext(CreateEntitiesContext) as unknown as (
    entity: ColumnInterface,
    {
      gridColumnStart,
      disableStacking,
    }: { gridColumnStart: number; disableStacking?: boolean },
  ) => JSX.Element
  const renderedEntities: JSX.Element[] = []
  let gridColumnStart = 1

  const columnEntities = useColumnEntities(entity)

  columnEntities.forEach(columnEntity => {
    renderedEntities.push(
      createEntityElement(columnEntity, {
        gridColumnStart,
        disableStacking: entity.disableStacking,
      }),
    )

    gridColumnStart += columnEntity.size
  })

  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.backgroundFileId,
    mobileBackgroundFileId: entity.mobileBackgroundFileId,
  })

  return isVisible ? (
    <StyleWrapper
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
    >
      <CommonRow
        {...entity}
        backgroundImage={backgroundImage}
        mobileBackgroundImage={mobileBackgroundImage}
        disableColumnGap={renderedEntities.length === 1}
      >
        {renderedEntities}
      </CommonRow>
    </StyleWrapper>
  ) : (
    <div />
  )
}

export default memo(Row)
