import React from 'react'
import { CommonImageInterface } from 'common/types/entities/ImageInterface'
import { addUrlSchemaToTheLink } from 'common/utils/urlUtils'
import ImageBackgroundUi from './ui/ImageBackgroundUi'
import ImageUi from './ui/ImageUi'
import ImageWrapperUi from './ui/ImageWrapperUi'
import PictureUi from './ui/PictureUi'

export enum ImageActionEnum {
  ShowPopup = 'ShowPopup',
  OpenUrl = 'OpenUrl',
}

interface ImageProps extends CommonImageInterface {
  src: string
  attrId: string
  loading?: 'lazy' | 'eager'
  onClick?: (e: React.SyntheticEvent) => void
  pointer?: boolean
  link?: string
  openNewTab?: boolean
}

function Image({
  attrId,
  alt,
  ratio,
  blur,
  border,
  src,
  width,
  mobileWidth,
  loading = 'lazy',
  boxShadow,
  mobileBoxShadow,
  alignSelf,
  mobileAlignSelf,
  onClick,
  pointer = false,
  link,
  openNewTab,
  mobileBorder,
  background,
  mobileBackground,
}: ImageProps) {
  const image = (
    <ImageUi
      onClick={onClick}
      pointer={pointer}
      id={attrId}
      src={src}
      alt={alt}
      width={width}
      mobileWidth={mobileWidth}
      fullHeight={!ratio}
      blur={blur}
      border={border}
      mobileBorder={mobileBorder}
      loading={loading}
      boxShadow={boxShadow}
      mobileBoxShadow={mobileBoxShadow}
    />
  )

  const imageWithBackground = (
    <>
      <ImageUi
        onClick={onClick}
        pointer={pointer}
        id={attrId}
        src={src}
        alt={alt}
        width={width}
        mobileWidth={mobileWidth}
        fullHeight={!ratio}
        blur={blur}
        borderRadius={border?.radius}
        mobileBorderRadius={mobileBorder?.radius}
        loading={loading}
        boxShadow={boxShadow}
        mobileBoxShadow={mobileBoxShadow}
      />
      <ImageBackgroundUi
        border={border}
        mobileBorder={mobileBorder}
        width={width}
        onClick={onClick}
        mobileWidth={mobileWidth}
        background={background}
        mobileBackground={mobileBackground}
      />
    </>
  )

  const isBackgroundSet = background || mobileBackground

  return (
    <ImageWrapperUi alignSelf={alignSelf} mobileAlignSelf={mobileAlignSelf}>
      <PictureUi ratio={ratio}>
        {link ? (
          <a
            href={addUrlSchemaToTheLink(link)}
            rel="noopener noreferrer"
            target={openNewTab ? '_blank' : '_self'}
          >
            {isBackgroundSet ? imageWithBackground : image}
          </a>
        ) : (
          <>{isBackgroundSet ? imageWithBackground : image}</>
        )}
      </PictureUi>
    </ImageWrapperUi>
  )
}

export default Image
