import React from 'react'
import { useDispatch } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonWebinarRegistrationDate from 'common/components/entities/WebinarRegistrationDate/WebinarRegistrationDate'
import WebinarRegistrationDateInterface from 'common/types/entities/WebinarRegistrationDateInterface'
import { changeField } from 'publisher/actions/optInActions'
import FieldErrors from '../../../components/FieldErrors'
import {
  usePage,
  pageSelectors,
  optInSelectors,
  useOptIn,
} from '../../../store'

type WebinarRegistrationDateProps = {
  entity: WebinarRegistrationDateInterface
}

function WebinarRegistrationDate({ entity }: WebinarRegistrationDateProps) {
  const dispatch = useDispatch()
  // @ts-ignore
  const locale = usePage(pageSelectors.getLocale)
  const errors = useOptIn(optIn =>
    optInSelectors.getFieldErrors(optIn, 'datetime'),
  )
  const time = useOptIn(optIn =>
    optInSelectors.getFieldValue(optIn, 'datetime'),
  )

  function handleChangeField(value: string | number) {
    dispatch(changeField('datetime', value))
  }

  return (
    <StyleWrapper
      mobile={entity.appearance.mobile}
      desktop={entity.appearance.desktop}
      styles={{ ...entity.margin }}
      mobileStyles={{ ...entity.mobileMargin }}
    >
      <CommonWebinarRegistrationDate
        attrId={entity.htmlAttrId}
        interval={entity.interval}
        spaceBetween={entity.spaceBetween}
        scheduledHours={entity.scheduledHours}
        timeFormat={entity.timeFormat}
        changeTime={handleChangeField}
        locale={locale}
        time={time}
      />
      {/*@ts-ignore*/}
      <FieldErrors errors={errors} />
    </StyleWrapper>
  )
}

export default WebinarRegistrationDate
