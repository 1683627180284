import styled, { css } from 'styled-components'

const Button = styled.button.attrs(props => {
  const additionalAttrs = {}
  if (props.target === '_blank') {
    additionalAttrs.rel = 'noopener noreferrer'
  }
  if (process.env.NODE_ENV === 'test') {
    additionalAttrs['data-testid'] = props.dataTestId
  }
  return additionalAttrs
})`
  display: flex !important; // otherwise as="a" could overwrite it
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  background: rgba(1, 116, 199, 1);
  border: none;
  text-align: center;
  padding: 9px 25px;
  cursor: pointer;
  opacity: 1;
  word-break: break-word;
  align-self: center;
  outline: none;
  transition: 0.3s;

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }

  &:hover {
    color: ${p => p.styles.color};
    ${p => p.hoverStyles.textColor && `color: ${p.hoverStyles.textColor}`};
    ${p =>
      p.hoverStyles.backgroundColor &&
      `background: ${p.hoverStyles.backgroundColor}`};
    ${p =>
      p.hoverStyles.transform &&
      p.hoverStyles.offset &&
      p.hoverStyles.transform !== 'none' &&
      `transform: ${p.hoverStyles.transform}(${
        p.hoverStyles.transform === 'scale'
          ? `1.${
              p.hoverStyles.offset < 10
                ? `0${p.hoverStyles.offset}`
                : p.hoverStyles.offset
            }`
          : `${p.hoverStyles.offset}px`
      });`};
  }

  &:hover small {
    ${p =>
      p.hoverStyles.subTextColor && `color: ${p.hoverStyles.subTextColor}`};
  }

  ${p =>
    p.as === 'a' &&
    css`
      display: inline-block;
      text-decoration: none;
      box-sizing: border-box;
    `}
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles};

    &:hover {
      color: ${p => p.mobileStyles.color};
      ${p =>
        p.hoverMobileStyles.transform &&
        p.hoverMobileStyles.offset &&
        p.hoverMobileStyles.transform !== 'none' &&
        `transform: ${p.hoverMobileStyles.transform}(${
          p.hoverMobileStyles.offset === 'scale'
            ? `1.${
                p.hoverMobileStyles.offset < 10
                  ? `0${p.hoverMobileStyles.offset}`
                  : p.hoverMobileStyles.offset
              }`
            : `${p.hoverMobileStyles.offset}px`
        });`};
      ${p =>
        p.hoverMobileStyles.textColor &&
        `color: ${p.hoverMobileStyles.textColor}`};
      ${p =>
        p.hoverMobileStyles.backgroundColor &&
        `background: ${p.hoverMobileStyles.backgroundColor}`}
    }
    &:hover small {
      ${p =>
        p.hoverMobileStyles.subTextColor &&
        `color: ${p.hoverMobileStyles.subTextColor}`};
    }
  }

  ${({ styles = {} }) => styles}
`

export default Button
