import axios from 'axios'
import { SurveysResults } from '../store/optIn/optInStateInterface'

export const optIn = (
  fields: Record<string, any>,
  datetime: string,
  timeZone: string,
  popupId: string,
  isDesktop: boolean,
  surveysResults: SurveysResults,
  entityId: string,
  checkBoxIds: string[],
  recaptchaToken?: string,
) => {
  return axios.post(location.href, {
    optin: {
      fields,
      datetime,
      timeZone,
      popupId,
      isDesktop,
      surveysResults,
      entityId,
      captcha: recaptchaToken,
      checkBoxIds,
    },
  })
}
