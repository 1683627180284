import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Overlay from 'common/components/core/Overlay'
import { closeCookieExists } from 'publisher/utils/cookie'
import { closePopup, showPopup } from '../../actionsManagement'
import Popup from '../../components/entities/Popup'
import useManagement, { selectors } from '../../hooks/useManagement'
import usePage from '../../hooks/usePage'
import { getTriggeredPopupId } from '../../reducers/managementReducer'
import { getFirstOnExitPopup, getPageId } from '../../reducers/pageReducer'
import { setCloseCookie } from '../../utils/cookie'
import PopupWrapper from '../ui/PopupWrapper'

export function OnExitPopup() {
  const popup = usePage(getFirstOnExitPopup)
  const triggeredPopupId = useManagement(getTriggeredPopupId)
  const pageId = usePage(getPageId)
  const isPopupActive = useManagement(m =>
    selectors.isPopupActive(m, popup && popup.id),
  )
  const dispatch = useDispatch()

  const notEqualsTriggeredPopup = popup => popup.id !== triggeredPopupId

  const onLeaveHandler = useCallback(
    event => {
      if (closeCookieExists(pageId, popup.id)) {
        return
      }
      if (
        event.clientY < 50 &&
        event.relatedTarget == null &&
        event.target.nodeName.toLowerCase() !== 'select'
      ) {
        dispatch(showPopup(popup.id))
        document.removeEventListener('mouseout', onLeaveHandler)
      }
    },
    [dispatch, pageId, popup],
  )

  useEffect(() => {
    if (popup && !closeCookieExists(pageId, popup.id)) {
      document.addEventListener('mouseout', onLeaveHandler)
    }
  }, [onLeaveHandler, pageId, popup])

  const handleClose = (closePopupId, closedDays) => () => {
    setCloseCookie(pageId, closePopupId, closedDays)
    dispatch(closePopup)
  }

  // I guess the popup will not be rendered, it renders in TriggeredPopup component
  return (
    isPopupActive &&
    notEqualsTriggeredPopup(popup) && (
      <React.Fragment>
        <PopupWrapper>
          <Popup
            entity={popup}
            closePopup={handleClose(popup.id, popup.options.closedDays)}
          />
        </PopupWrapper>
        <Overlay dark />
      </React.Fragment>
    )
  )
}

export default OnExitPopup
