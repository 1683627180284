/* eslint-disable no-underscore-dangle */
import React, { Suspense } from 'react'
import { hydrate } from 'react-dom'
import { useSSR } from 'react-i18next'
import 'common/utils/domNormalizeHack'
import App from 'publisher/app'
import { Query, QueryKeys } from 'publisher/components/core/MatchMedia'
import configureStore from 'publisher/entrypoints/simple/configureStore'
import i18n from 'publisher/i18n-client'
import { selectors } from 'publisher/reducers/optInReducer'
import { selectors as pageSelectors } from 'publisher/reducers/pageReducer'
import { initialiseAxiosInterceptors } from 'publisher/utils/registerAxiosInterceptors'
import { normalizeStringBoolean } from '../../store'
import createEntityElement from './createEntityElement'
import createPage from './createPage'

initialiseAxiosInterceptors()

const preloadedState = window.__PRELOADED_STATE__
delete window.__PRELOADED_STATE__
// Allow the passed state to be garbage-collected
const isDesktop = window.matchMedia(Query[QueryKeys.DESKTOP]).matches
preloadedState.management.isDesktop = isDesktop
preloadedState.management.isMobile = normalizeStringBoolean(
  preloadedState.management.isMobile,
)
const store = configureStore(preloadedState)
const rootElement = document.getElementById('app')

if (pageSelectors.getCountryFieldId(preloadedState.page)) {
  preloadedState.optIn.fields.country = selectors.getCustomerCountry(
    preloadedState.optIn,
  )
}

if (process.env.NODE_ENV === 'staging') {
  document.body.setAttribute('js-loaded', true)
}
document.body.setAttribute('js-loaded', true)

const BaseApp = () => {
  useSSR(window.initialI18nStore, window.initialLanguage, { i18n })
  return (
    <Suspense fallback={<div>loading ...</div>}>
      <App
        store={store}
        createEntityElement={createEntityElement}
        createPage={createPage}
      />
    </Suspense>
  )
}

hydrate(<BaseApp />, rootElement)
