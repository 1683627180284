import React, { MouseEvent, useState } from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonFaq, { FaqItem } from 'common/components/entities/Faq'
import FaqInterface, {
  FaqItemInterface,
} from 'common/types/entities/FaqInterface'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'
import { pageSelectors, usePage } from '../../store'

type FaqProps = {
  entity: FaqInterface
}

function Faq({ entity }: FaqProps) {
  const [activeIndex, setActiveIndex] = useState<null | number>(null)
  const faqItems = usePage(page =>
    pageSelectors.getChildrenEntities(page, entity.childIds),
  ) as FaqItemInterface[]

  function toggleCollapse(index: number) {
    return function (e: MouseEvent) {
      e.stopPropagation()
      if (activeIndex === index) {
        setActiveIndex(null)
      } else {
        setActiveIndex(index)
      }
    }
  }

  return (
    <StyleWrapper
      mobile={entity.appearance.mobile}
      desktop={entity.appearance.desktop}
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
    >
      <CommonFaq
        mobilePadding={entity.mobilePadding}
        padding={entity.padding}
        htmlAttrId={entity.htmlAttrId}
        spaceBetweenItems={entity.spaceBetweenItems}
      >
        {faqItems.map((faqItem, index) => (
          <FaqItem
            key={faqItem.id}
            showIcons={entity.showIcons}
            mobileShowIcons={entity.mobileShowIcons}
            itemBorderRadius={entity.itemBorderRadius}
            itemPadding={entity.itemPadding}
            mobileItemPadding={entity.mobileItemPadding}
            backgroundColor={entity.backgroundColor}
            mobileBackgroundColor={entity.mobileBackgroundColor}
            titleColor={entity.titleColor}
            mobileTitleColor={entity.mobileTitleColor}
            fontSize={entity.titleFontSize}
            mobileFontSize={entity.mobileTitleFontSize}
            fontFamily={entity.titleFontFamily}
            fontStyle={entity.titleFontStyle}
            fontWeight={entity.titleFontWeight}
            title={faqItem.title}
            expanded={activeIndex === index}
            toggleCollapse={toggleCollapse(index)}
            iconClassName={entity.iconClassName}
            closedIconClassName={entity.closedIconClassName}
            iconColor={entity.iconColor}
            mobileIconColor={entity.mobileIconColor}
            boxShadow={entity.boxShadow}
            mobileBoxShadow={entity.mobileBoxShadow}
          >
            <BaseNodeChildren childIds={faqItem.childIds} />
          </FaqItem>
        ))}
      </CommonFaq>
    </StyleWrapper>
  )
}

export default Faq
