import styled from 'styled-components'

const PopupDialog = styled.div.attrs(() => ({
  'data-testid': 'popup',
}))`
  border-radius: 6px;
  position: relative;
  transition: transform 0.3s ease-out;
  display: block;
  max-width: 600px; /* need for iframe width detection */
  width: 100%;
  ${p => p.theme.phone} {
    max-width: 350px;
    width: 100%;
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export const RemotePopupDialog = styled.div`
  border-radius: 6px;
  position: relative;
  transition: transform 0.3s ease-out;
  display: block;
  max-width: 600px; /* need for iframe width detection */
  width: 100%;
  @media only screen and (max-width: 500px) {
    max-width: 350px;
    width: 100%;
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export default PopupDialog
