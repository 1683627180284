import styled, { css } from 'styled-components'
import { AlignSelfType } from '../../../../types/styleTypes'

const ImageWrapperUi = styled.div<{
  alignSelf: AlignSelfType
  mobileAlignSelf?: AlignSelfType
}>`
  align-self: ${p => p.alignSelf};
  ${p => p.theme.phone} {
    align-self: ${p => p.mobileAlignSelf};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      align-self: ${p.mobileAlignSelf};
    `}
`

export default ImageWrapperUi
