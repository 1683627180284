import PropTypes from 'prop-types'
import React from 'react'
import FieldErrors from 'publisher/components/FieldErrors'
import useOptInSubmit from 'publisher/hooks/useOptInSubmit'
import BaseButton from '../BaseButton'

export const TEST_ID = 'send-form-button'

function SendFormButton({ entity }) {
  const { styles, mobileStyles, options, mobileOptions } = entity
  const [isLoading, errors, submit] = useOptInSubmit(entity)

  return (
    <React.Fragment>
      <BaseButton
        dataTestId={TEST_ID}
        handleClick={submit}
        disabled={isLoading}
        styles={styles}
        mobileStyles={mobileStyles}
        attrId={options.attrId}
        text={options.text}
        subText={options.subText}
        subTextColor={options.subTextColor}
        subTextFontSize={options.subTextFontSize}
        subTextFontWeight={options.subTextFontWeight}
        subTextFontStyle={options.subTextFontStyle}
        subTextFontFamily={options.subTextFontFamily}
        mobileSubTextColor={mobileOptions.subTextColor}
        mobileSubTextFontSize={mobileOptions.subTextFontSize}
        mobileSubTextFontWeight={mobileOptions.subTextFontWeight}
        mobileSubTextFontStyle={mobileOptions.subTextFontStyle}
        mobileSubTextFontFamily={mobileOptions.subTextFontFamily}
        iconClassNameBefore={entity.options.iconClassNameBefore}
        iconClassNameAfter={entity.options.iconClassNameAfter}
        borderType={options.borderType}
        mobileBorderType={mobileOptions.borderType}
        appearance={options.appearance}
        hoverStyles={options?.hover}
        hoverMobileStyles={mobileOptions?.hover}
      />
      <FieldErrors errors={errors} align="center" />
    </React.Fragment>
  )
}

SendFormButton.propTypes = {
  entity: PropTypes.shape({
    options: PropTypes.shape({
      redirectionType: PropTypes.string.isRequired,
      urlRedirect: PropTypes.string,
      attrId: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      subText: PropTypes.string,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontWeight: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      iconClassNameBefore: PropTypes.string,
      iconClassNameAfter: PropTypes.string,
      borderType: PropTypes.string.isRequired,
      appearance: PropTypes.shape({
        desktop: PropTypes.bool.isRequired,
        mobile: PropTypes.bool.isRequired,
      }).isRequired,
      hover: PropTypes.shape({
        textColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        subTextColor: PropTypes.string,
        transform: PropTypes.string,
        offset: PropTypes.number,
      }),
    }),
    mobileOptions: PropTypes.shape({
      borderType: PropTypes.string,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontWeight: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      hover: PropTypes.shape({
        textColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        subTextColor: PropTypes.string,
        transform: PropTypes.string,
        offset: PropTypes.number,
      }),
    }),
    mobileStyles: PropTypes.objectOf(PropTypes.string),
    styles: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default SendFormButton
