import PropTypes from 'prop-types'
import ErrorBoundary from 'publisher/components/ErrorBoundary'
import React, { createContext } from 'react'
import { Provider } from 'react-redux'
import Page from './components/Page'

export const CreateEntitiesContext = createContext(null)

const App = ({ store, createEntityElement, createPage }) => (
  <CreateEntitiesContext.Provider value={createEntityElement}>
    <Provider store={store}>
      <Page createEntityElement={createEntityElement} createPage={createPage} />
    </Provider>
  </CreateEntitiesContext.Provider>
)

App.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  createPage: PropTypes.func.isRequired,
  createEntityElement: PropTypes.func.isRequired,
}

export default App
