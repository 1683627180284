import {
  CHANGE_LECTURE_SESSION_ID,
  TURN_OFF_SESSION_LOGGING,
} from 'publisher/actionTypes'

export const setLectureSessionId = id => ({
  type: CHANGE_LECTURE_SESSION_ID,
  payload: id,
})

export const unsetLectureSessionId = {
  type: CHANGE_LECTURE_SESSION_ID,
  payload: null,
}

export const turnOffSessionLogging = {
  type: TURN_OFF_SESSION_LOGGING,
}
