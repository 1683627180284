import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { slugs } from 'common/constants/inputTypes'
import { changeField } from 'publisher/actions/optInActions'
import { optInSelectors, useOptIn } from 'publisher/store'

export const useFieldsStorage = (slug: string) => {
  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)

  const value = useOptIn(optIn => optInSelectors.getFieldValue(optIn, slug))

  useEffect(() => {
    if (slug === slugs.PASSWORD) return
    const valueFromStorage = sessionStorage.getItem(slug)
    if (valueFromStorage && !value) return
    if (slug) {
      sessionStorage.setItem(slug, value)
    }
  }, [slug, value])

  useEffect(() => {
    if (!slug) return
    if (slug === slugs.PASSWORD) return
    const valueFromStorage = sessionStorage.getItem(slug)
    if (valueFromStorage) {
      dispatch(changeField(slug, valueFromStorage))
    }
    setIsReady(true)
  }, [dispatch, slug])

  return { isReady }
}
