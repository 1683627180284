export const localeValues = {
  en: 'en',
  fr: 'fr',
  es: 'es',
  it: 'it',
  pt: 'pt',
  de: 'de',
  nl: 'nl',
  ru: 'ru',
  jp: 'jp',
  ar: 'ar',
  tr: 'tr',
  zh: 'zh',
  sv: 'sv',
  ro: 'ro',
  cs: 'cs',
  hu: 'hu',
  sk: 'sk',
  dk: 'dk',
  id: 'id',
  pl: 'pl',
  el: 'el',
  sr: 'sr',
  hi: 'hi',
  nb: 'nb',
  th: 'th',
  sl: 'sl',
  ua: 'ua',
  sq: 'sq',
}

export const localeNames = {
  [localeValues.en]: 'English',
  [localeValues.fr]: 'French',
  [localeValues.es]: 'Spanish',
  [localeValues.it]: 'Italian',
  [localeValues.pt]: 'Portuguese',
  [localeValues.de]: 'German',
  [localeValues.nl]: 'Dutch',
  [localeValues.ru]: 'Russian',
  [localeValues.jp]: 'Japanese',
  [localeValues.ar]: 'Arabic',
  [localeValues.tr]: 'Turkish',
  [localeValues.zh]: 'Chinese',
  [localeValues.sv]: 'Svenska',
  [localeValues.ro]: 'Română',
  [localeValues.cs]: 'Čeština',
  [localeValues.hu]: 'Magyar',
  [localeValues.sk]: 'Slovenský',
  [localeValues.dk]: 'Dansk',
  [localeValues.id]: 'Indonesian',
  [localeValues.pl]: 'Polski',
  [localeValues.el]: 'Ελληνικά',
  [localeValues.sr]: 'Srpski',
  [localeValues.hi]: 'Hindi',
  [localeValues.nb]: 'Norsk',
  [localeValues.th]: 'ไทย',
  [localeValues.sl]: 'Slovenič',
  [localeValues.ua]: 'Український',
  [localeValues.sq]: 'Shqiptare',
}
