import styled from 'styled-components'

const BodyUi = styled.div`
  position: relative;
  width: 100%;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export default BodyUi
