import Cookie from 'js-cookie'

export const closeCookieExists = (pageId, popupId) =>
  Cookie.get(`close_systeme_page_${pageId}_popup_${popupId}`)

export const setCloseCookie = (pageId, popupId, closedDays = 0) => {
  const options = closedDays > 0 ? { expires: +closedDays } : null
  Cookie.set(`close_systeme_page_${pageId}_popup_${popupId}`, '1', options)
}

export const setWebinarStartTimeCookie = (pageId, time, postFix) => {
  const options = { expires: new Date(time) }
  Cookie.set(`webinar_start_time_${pageId}_${postFix}`, time, options)
}

export const getWebinarStartTimeCookie = (pageId, postFix) =>
  Cookie.get(`webinar_start_time_${pageId}_${postFix}`)

export const setCounterEndTimeCookie = (pageId, time, postFix) => {
  const options = { expires: 30 }
  Cookie.set(`counter_start_time_${pageId}_${postFix}`, time, options)
}

export const getCounterEndTimeCookie = (pageId, postFix) =>
  Cookie.get(`counter_start_time_${pageId}_${postFix}`)

export const getNotificationCookie = pageId =>
  Cookie.get(`notification_page_id_${pageId}`)

export const removeNotificationCookie = pageId =>
  Cookie.remove(`notification_page_id_${pageId}`)
