import { Middleware } from 'redux'
import { REMOVE_NODE } from 'client/actionTypes'
import { getAllDescendantIds } from 'client/reducers/pageReducer'
import { saveAutomationRulesState } from 'client/store/automationRules/automationRulesActions'
import { RootState } from 'client/store/index'

export const removeLostRulesMiddleware: Middleware<
  {}, // Most middleware do not modify the dispatch return value
  RootState
> = storeApi => next => action => {
  if (action.type === REMOVE_NODE) {
    const state = storeApi.getState() // correctly typed as RootState

    const page = state.page.present

    const descendantIds = getAllDescendantIds(page, action.payload.id)

    const ids = [action.payload.id, ...descendantIds]

    const rules = state.automationRules

    const editedPageAutomationRules = rules.editedPageAutomationRules.filter(
      rule =>
        !rule.automationTriggers.some(
          trigger =>
            ids.includes(trigger.buttonId) || ids.includes(trigger.checkBoxId),
        ),
    )
    storeApi.dispatch(
      saveAutomationRulesState({
        ...rules,
        editedPageAutomationRules,
      }),
    )
  }
  return next(action)
}
