import React, { useEffect, useState } from 'react'
import MobileMenuItemWrapper from 'common/components/entities/Menu/components/Mobile/components/MobileMenuItem/ui/MobileMenuItemWrapper'
import MobileMenuWrapper from 'common/components/entities/Menu/components/Mobile/components/MobileMenuItem/ui/MobileMenuWrapper'
import MobileSubMenuChevronUi from 'common/components/entities/Menu/components/Mobile/components/MobileMenuItem/ui/MobileSubMenuChevronUi'
import MobileSubMenuWrapperUi from 'common/components/entities/Menu/components/Mobile/components/MobileMenuItem/ui/MobileSubMenuWrapper'
import MenuItemUi, {
  MenuItemUiProps,
} from 'common/components/entities/Menu/components/ui/MenuItemUi'
import useActiveMenuItem from 'common/components/entities/Menu/hooks/useActiveMenuItem'
import { MenuItemInterface } from 'common/components/entities/Menu/types/MenuInterface'
import { hasSubMenu } from 'common/components/entities/Menu/utils/hasSubMenu'
import { isBlogReplacementPlaceholder } from 'common/utils/blogReplacementsUtils'

interface MobileMenuItemProps
  extends Omit<MenuItemUiProps, 'isActive' | 'hasSubMenu'> {
  attrId: string
  item: MenuItemInterface
  index: number
  spacing?: number
  isEditing?: boolean
}

const Index = ({
  attrId,
  item,
  index,
  spacing,
  isEditing,
  ...rest
}: MobileMenuItemProps) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const { target, text, subMenuItems, link } = item
  const [position, setPosition] = useState<'relative' | 'absolute'>('absolute')
  const isOpenInEditMode = (isEditing && item.subMenuOpen) || false
  const activeMenuItemIndex = useActiveMenuItem(item, index)
  useEffect(() => {
    if (isSubMenuOpen || isOpenInEditMode) {
      setPosition('relative')
    } else {
      setTimeout(() => setPosition('absolute'), 150)
    }
  }, [isSubMenuOpen, isOpenInEditMode])

  return (
    <li>
      <MobileMenuWrapper>
        <MobileMenuItemWrapper onClick={() => setIsSubMenuOpen(prev => !prev)}>
          <MenuItemUi
            as={hasSubMenu(item) ? 'span' : 'a'}
            isMobile
            href={link}
            target={target}
            isActive={activeMenuItemIndex[0] === index}
            {...rest}
          >
            {text}
          </MenuItemUi>
          {hasSubMenu(item) && (
            <MobileSubMenuChevronUi
              opened={isSubMenuOpen || isOpenInEditMode}
              color={
                activeMenuItemIndex[0] === index
                  ? rest.mobileActiveColor || rest.activeColor
                  : rest.mobileColor || rest.color || ''
              }
              className="fas fa-chevron-down"
            />
          )}
        </MobileMenuItemWrapper>
        {hasSubMenu(item) && (
          <MobileSubMenuWrapperUi
            position={position}
            opened={isSubMenuOpen || isOpenInEditMode}
            spacing={spacing}
          >
            {subMenuItems
              ?.filter(subItem => !isBlogReplacementPlaceholder(subItem.link))
              .map((subItem, subIndex) => (
                <MenuItemUi
                  as={'a'}
                  isMobile
                  key={`${attrId}-mobile-link-subItem-${index}-${subIndex}-${
                    subItem.link || subItem.text
                  }`}
                  href={subItem.link}
                  target={subItem.target}
                  isActive={
                    activeMenuItemIndex[1] === subIndex &&
                    activeMenuItemIndex[0] === index
                  }
                  {...rest}
                >
                  {subItem.text}
                </MenuItemUi>
              ))}
          </MobileSubMenuWrapperUi>
        )}
      </MobileMenuWrapper>
    </li>
  )
}

export default Index
