import styled from 'styled-components'
import SurveyAnswerUi from './SurveyAnswerUi'

type SurveyAnswerRadioButtonUiProps = {
  outlineColor: string
  isTextArea?: boolean
}

const SurveyAnswerRadioButtonUi = styled.div<SurveyAnswerRadioButtonUiProps>`
  width: 25px;
  height: 25px;
  background-size: cover;
  border-radius: 12.5px;
  border: 1px solid rgba(216, 217, 217, 1);
  background-color: rgba(255, 255, 255, 1);
  ${p =>
    p.isTextArea &&
    `
    position: absolute;
    top: 15px;
    right: 10px;
`}
  ${p =>
    p.isTextArea
      ? `&:hover {
    background-image: url(${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/checked.png);
    background-color: ${p.outlineColor};
    border-color: ${p.outlineColor};
  }`
      : `${SurveyAnswerUi}:hover & {
    background-image: url(${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/checked.png);
    background-color: ${p.outlineColor};
    border-color: ${p.outlineColor};
  }`}
`

export default SurveyAnswerRadioButtonUi
