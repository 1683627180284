import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import SelectUi from 'common/components/core/Select/ui/Select'
import { getLanguageCodeByLocale } from 'common/enums/LanguageCodeEnum'
import { changeField } from 'publisher/actions/optInActions'
import useOptIn, { selectors as optInSelectors } from 'publisher/hooks/useOptIn'
import usePage, { selectors } from 'publisher/hooks/usePage'
import getCountries from '../../../utils/getCountries'
import FieldErrors from '../../FieldErrors'
import { useFieldsStorage } from './hooks/use-fields-storage'

function CountryField({ entity }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const locale = usePage(selectors.getLocale)
  const [countries, setCountries] = useState([])
  const availableCountries = useOptIn(optInSelectors.getAvailableCountries)
  const errors = useOptIn(optIn =>
    optInSelectors.getFieldErrors(optIn, entity.options.slug),
  )
  const availableCountriesCodes = Object.keys(availableCountries)
  const value = useOptIn(optIn =>
    optInSelectors.getFieldValue(optIn, entity.options.slug),
  )

  function changeCountry(e) {
    return dispatch(changeField(entity.options.slug, e.target.value))
  }

  useFieldsStorage(entity.options.slug)

  useEffect(() => {
    if (availableCountriesCodes.length === 0) {
      setCountries(getCountries(getLanguageCodeByLocale(locale)))
    } else {
      setCountries(
        Object.keys(availableCountries).map(code => ({
          code,
          name: availableCountries[code],
        })),
      )
      if (availableCountriesCodes.length === 1) {
        dispatch(changeField(entity.options.slug, availableCountriesCodes[0]))
      }
    }
  }, [locale])

  const { marginTop, marginLeft, marginBottom, marginRight, ...restStyles } =
    entity.styles
  const {
    marginTop: mobileMarginTop,
    marginRight: mobileMarginRight,
    marginBottom: mobileMarginBottom,
    marginLeft: mobileMarginLeft,
    ...restMobileStyles
  } = entity.mobileStyles

  return (
    <StyleWrapper
      desktop={entity.options.appearance.desktop}
      mobile={entity.options.appearance.mobile}
      styles={{
        marginTop,
        marginLeft,
        marginBottom,
        marginRight,
      }}
      mobileStyles={{
        marginTop: mobileMarginTop,
        marginLeft: mobileMarginRight,
        marginBottom: mobileMarginBottom,
        marginRight: mobileMarginLeft,
      }}
    >
      <SelectUi
        value={value}
        onChange={changeCountry}
        styles={restStyles}
        mobileStyles={restMobileStyles}
      >
        <option>{t('entities.field.country.placeholder')}</option>
        {countries.map(({ code, name }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </SelectUi>
      <FieldErrors errors={errors} />
    </StyleWrapper>
  )
}

CountryField.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      slug: PropTypes.string,
      appearance: PropTypes.shape({
        desktop: PropTypes.bool.isRequired,
        mobile: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
    styles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    mobileStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    type: PropTypes.string.isRequired,
  }),
}

export default CountryField
