import React from 'react'
import { entity } from 'common/types'
import Overlay from 'common/components/core/Overlay'
import { getEntityById, getPageId } from 'publisher/reducers/pageReducer'
import PopupWrapper from 'publisher/pages/ui/PopupWrapper'
import { closePopup } from 'publisher/actionsManagement'
import { useDispatch } from 'react-redux'
import Popup from '../../components/entities/Popup'
import useManagement, {
  selectors as managementSelectors,
} from '../../hooks/useManagement'
import usePage from '../../hooks/usePage'
import { setCloseCookie } from '../../utils/cookie'

const TriggeredPopup = () => {
  const triggeredPopupId = useManagement(
    managementSelectors.getTriggeredPopupId,
  )
  const popup = usePage(page => getEntityById(page, triggeredPopupId))
  const pageId = usePage(getPageId)
  const dispatch = useDispatch()

  if (!popup) {
    return null
  }

  const handleClose = (popupId, closedDays) => {
    setCloseCookie(pageId, popupId, closedDays)
    dispatch(closePopup)
  }

  return (
    <React.Fragment>
      <PopupWrapper>
        <Popup
          entity={popup}
          overflow="auto"
          closePopup={() => handleClose(popup.id, popup.options.closedDays)}
        />
      </PopupWrapper>
      <Overlay dark />
    </React.Fragment>
  )
}

TriggeredPopup.propTypes = {
  activePopup: entity,
}

TriggeredPopup.defaultProps = {
  activePopup: null,
}

export default TriggeredPopup
