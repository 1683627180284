import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { useManagement } from 'publisher/store'
import { useOptIn } from 'publisher/store'
import { usePage } from 'publisher/store'
import managementSelectors from 'publisher/store/management/managementSelectors'
import { optInRecaptchaValidationFailed } from 'publisher/store/optIn/optInActions'
import { getSubmittedRecaptchas } from 'publisher/store/optIn/optInSelectors'
import pageSelectors from 'publisher/store/page/pageSelectors'

export const CAPTCHA_EMPTY_FIELD_ERROR = 'CAPTCHA_EMPTY_FIELD_ERROR'

export function useRecaptchaValidation(optInButtonEntity: OldEntityInterface) {
  const captchaOpenKey = useManagement(managementSelectors.getCaptcha)
  const isDesktop = useManagement(managementSelectors.isDesktop)
  const submittedRecaptchas = useOptIn(getSubmittedRecaptchas)
  const popupId = usePage(p =>
    pageSelectors.getAscendantPopupId(p, optInButtonEntity),
  )
  const mandatoryOptInRecaptcha = usePage(p =>
    pageSelectors.getVisibleMandatoryOptInRecaptchas(p, isDesktop, popupId),
  )[0]
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const validateRecaptchas = () => {
    let recaptchaToken
    let error
    if (captchaOpenKey && mandatoryOptInRecaptcha) {
      const submittedRecaptcha = submittedRecaptchas.find(
        el => el.id === mandatoryOptInRecaptcha.id,
      )
      if (submittedRecaptcha) {
        recaptchaToken = submittedRecaptcha.token
      } else {
        dispatch(
          optInRecaptchaValidationFailed({
            id: mandatoryOptInRecaptcha.id,
            error: t('validation.not_empty'),
          }),
        )
        error = CAPTCHA_EMPTY_FIELD_ERROR
      }
    }
    return { recaptchaToken, error }
  }

  return { validateRecaptchas }
}
