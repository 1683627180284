import React from 'react'
import { useDispatch } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import { PhoneInputProps } from 'common/components/core/PhoneInput'
import FieldIconUi from 'common/components/entities/Field/FieldIconUi'
import FieldWrapperUi from 'common/components/entities/Field/FieldWrapperUi'
import PhoneNumberFieldUi from 'common/components/entities/Field/PhoneNumberFieldUi'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { getLanguageCodeByLocale } from 'common/enums/LanguageCodeEnum'
import { changeField } from 'publisher/actions/optInActions'
import { useFieldsStorage } from 'publisher/components/entities/Field/hooks/use-fields-storage'
import usePage, { selectors } from 'publisher/hooks/usePage'
import { useOptIn } from 'publisher/store'
import {
  getCustomerCountry,
  getFieldErrorsBySlug,
  getFieldValueBySlug,
} from 'publisher/store/optIn/optInSelectors'
import FieldErrors from '../../FieldErrors'

export interface PhoneNumberFieldProps {
  entity: {
    id: string
    options: {
      slug: string
      attrId: string
      appearance: {
        desktop: boolean
        mobile: boolean
      }
      iconClassName: string
      iconColor: string
      mobileIconColor: string
      fontSize: string
      borderType: string
      placeholder: string
      placeholderColor: string
    }
    styles: Record<string, any>
    mobileStyles: Record<string, any>
    mobileOptions: Record<string, any>
  }
}

function PhoneNumberField({ entity }: PhoneNumberFieldProps) {
  const dispatch = useDispatch()
  const errors = useOptIn(optIn =>
    getFieldErrorsBySlug(optIn, FieldSlugEnum.PhoneNumber),
  )
  const locale = usePage(selectors.getLocale)

  const phoneNumber = useOptIn(optIn =>
    getFieldValueBySlug(optIn, FieldSlugEnum.PhoneNumber),
  )
  const customerCountry = useOptIn(getCustomerCountry) || undefined

  const handlePhoneNumber: PhoneInputProps['onChange'] = ({
    unformatedPhone,
  }) => {
    dispatch(changeField(entity.options.slug, unformatedPhone))
  }

  const { isReady } = useFieldsStorage(entity.options.slug)

  const { marginTop, marginLeft, marginBottom, marginRight, ...restStyles } =
    entity.styles
  const {
    marginTop: mobileMarginTop,
    marginRight: mobileMarginRight,
    marginBottom: mobileMarginBottom,
    marginLeft: mobileMarginLeft,
    ...restMobileStyles
  } = entity.mobileStyles

  return (
    <StyleWrapper
      desktop={entity.options.appearance.desktop}
      mobile={entity.options.appearance.mobile}
      styles={{
        marginTop,
        marginLeft,
        marginBottom,
        marginRight,
      }}
      mobileStyles={{
        marginTop: mobileMarginTop,
        marginLeft: mobileMarginRight,
        marginBottom: mobileMarginBottom,
        marginRight: mobileMarginLeft,
      }}
    >
      {typeof window !== 'undefined' && isReady && (
        <FieldWrapperUi>
          {entity.options.iconClassName && (
            <FieldIconUi
              color={entity.options.iconColor}
              mobileColor={entity.options.mobileIconColor}
              fontSize={entity.styles.fontSize}
              mobileFontSize={entity?.mobileStyles?.fontSize}
              className={entity.options.iconClassName}
            />
          )}
          <PhoneNumberFieldUi
            initialCountry={customerCountry?.toLowerCase()}
            value={phoneNumber || ''}
            onChange={handlePhoneNumber}
            hasIcon={!!entity.options.iconClassName}
            mobileStyles={restMobileStyles}
            borderType={entity.options?.borderType}
            mobileBorderType={entity.mobileOptions.borderType}
            placeholder={entity.options?.placeholder}
            placeholderColor={entity.options.placeholderColor}
            mobilePlaceholderColor={entity.mobileOptions.placeholderColor}
            styles={{ ...restStyles }}
            countrySelectorStyleProps={{
              dropdownStyleProps: {
                style: { fontSize: entity.styles.fontSize },
                searchInputStyle: { fontSize: entity.styles.fontSize },
              },
            }}
            countrySelectorProps={{
              language: getLanguageCodeByLocale(locale),
            }}
            inputProps={{ id: entity.options.attrId }}
          />
        </FieldWrapperUi>
      )}
      <FieldErrors errors={errors} />
    </StyleWrapper>
  )
}

export default PhoneNumberField
