import PropTypes from 'prop-types'
import React, { memo, useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonRow from 'common/components/entities/Row/RowOld'
import * as types from 'common/types'
import { CreateEntitiesContext } from '../app'
import { useDelay } from '../hooks/useDelay'
import useFiles, { selectors as filesSelectors } from '../hooks/useFiles'
import { getChildrenEntities } from '../reducers/pageReducer'

function RowOld({ entity }) {
  const { styles, options, mobileOptions, mobileStyles, childIds } = entity
  const isVisible = useDelay(entity.options.delay)
  const backgroundFile = useFiles(files =>
    filesSelectors.getFile(files, options.backgroundFileId),
  )
  const mobileBackgroundFile = useFiles(files =>
    filesSelectors.getFile(files, mobileOptions.backgroundFileId),
  )
  const calculatedMobileBackgroundFile =
    typeof mobileOptions.backgroundFileId === 'undefined'
      ? backgroundFile
      : mobileBackgroundFile
  const createEntityElement = useContext(CreateEntitiesContext)
  const entities = useSelector(
    state => getChildrenEntities(state.page, childIds),
    shallowEqual,
  )
  const renderedEntities = []
  let gridColumnStart = 1

  entities.forEach(entity => {
    renderedEntities.push(
      createEntityElement(entity, {
        gridColumnStart,
        disableStacking: options.disableStacking,
      }),
    )

    gridColumnStart += entity.options.size
  })

  const { appearance = {} } = options
  const { desktop = true, mobile = true } = appearance
  return isVisible ? (
    <StyleWrapper desktop={desktop} mobile={mobile}>
      <CommonRow
        styles={styles}
        mobileStyles={mobileStyles}
        options={options}
        mobileOptions={mobileOptions}
        disableColumnGap={renderedEntities.length === 1}
        backgroundFile={backgroundFile}
        mobileBackgroundFile={calculatedMobileBackgroundFile}
      >
        {renderedEntities}
      </CommonRow>
    </StyleWrapper>
  ) : (
    <div />
  )
}

RowOld.propTypes = {
  entity: types.entity.isRequired,
  backgroundFile: PropTypes.objectOf(PropTypes.any),
}

RowOld.defaultProps = {
  childStyles: {},
  backgroundFile: null,
}

export default memo(RowOld)
