import { convertFromRaw } from 'draft-js'
import React, { useEffect, useState } from 'react'
import { OldTextEntityInterface } from 'common/types/entities/OldEntityInterface'
import { BorderType, PaddingType } from 'common/types/styleTypes'
import { HeadingTypeEnum } from '../../../enums/HeadingTypeEnum'
import { HeadlineInterface } from '../../../types/entities/HeadlineInterface'
import ContentItemUi from './ui/ContentItemUi'
import ContentItemWrapperUi from './ui/ContentItemWrapperUi'
import ContentTableContentUi from './ui/ContentTableContentUi'
import ContentTableHeaderIconUi from './ui/ContentTableHeaderIconUi'
import ContentTableHeaderIconWrapperUi from './ui/ContentTableHeaderIconWrapperUi'
import ContentTableHeaderTitleUi from './ui/ContentTableHeaderTitleUi'
import ContentTableHeaderUi from './ui/ContentTableHeaderUi'
import ContentTableUi from './ui/ContentTableUi'

type ContentTableProps = {
  padding: PaddingType
  mobilePadding: PaddingType
  iconClassName: string
  closedIconClassName?: string
  iconColor: string
  headerTitle: string
  headerFontSize: number
  headerColor: string
  backgroundColor?: string
  headerFontFamily?: string
  headerFontStyle?: string
  headerFontWeight?: string
  mobileBackgroundColor?: string
  headers: OldTextEntityInterface[]
  border: Partial<BorderType>
  contentColor: string
  contentFontSize: number
  contentFontFamily?: string
  contentFontStyle?: string
  contentFontWeight?: string
  contentItemGap?: number
  mobileBorder?: Partial<BorderType>
  headersTypes: string[]
  defaultState: boolean
}

function getText(heading: OldTextEntityInterface | HeadlineInterface) {
  const contentState = convertFromRaw(
    JSON.parse(
      'rawContentState' in heading
        ? heading.rawContentState
        : heading.options.rawContentState,
    ),
  )
  return contentState.getPlainText()
}

type HeaderWithType = {
  header: OldTextEntityInterface | HeadlineInterface
  headerType: HeadingTypeEnum
}

function getHeadersWithTypes(
  headers: (OldTextEntityInterface | HeadlineInterface)[],
  headersTypes: HeadingTypeEnum[],
) {
  return headers.reduce((acc, header) => {
    const contentState = convertFromRaw(
      JSON.parse(
        'rawContentState' in header
          ? header.rawContentState
          : header.options.rawContentState,
      ),
    )
    const headerType = contentState.getFirstBlock().getType() as HeadingTypeEnum

    if (
      Object.values(HeadingTypeEnum).includes(headerType) &&
      headersTypes.includes(headerType)
    ) {
      return [...acc, { header, headerType }]
    }

    return acc
  }, [] as HeaderWithType[])
}

const headersOffsetMap = {
  [HeadingTypeEnum.HeaderOne]: 0,
  [HeadingTypeEnum.HeaderTwo]: 30,
  [HeadingTypeEnum.HeaderThree]: 60,
  [HeadingTypeEnum.HeaderFour]: 90,
  [HeadingTypeEnum.HeaderFive]: 120,
  [HeadingTypeEnum.HeaderSix]: 150,
}

function getOffset(
  headerWithType: HeaderWithType,
  rootHeaderType: HeadingTypeEnum,
) {
  const contentState = convertFromRaw(
    JSON.parse(
      'rawContentState' in headerWithType.header
        ? (headerWithType.header as HeadlineInterface).rawContentState
        : (headerWithType.header as OldTextEntityInterface).options
            .rawContentState,
    ),
  )

  const headerType = contentState.getFirstBlock().getType() as HeadingTypeEnum

  if (Object.values(HeadingTypeEnum).includes(headerType) === false) {
    return 0
  }

  return headersOffsetMap[headerType] - headersOffsetMap[rootHeaderType]
}

function compareHeaderTypes(first: HeadingTypeEnum, second: HeadingTypeEnum) {
  if (headersOffsetMap[first] < headersOffsetMap[second]) {
    return -1
  }

  return 1
}

function ContentTable({
  iconClassName,
  iconColor,
  backgroundColor,
  mobileBackgroundColor,
  padding,
  mobilePadding,
  headers,
  contentColor,
  contentFontSize,
  border,
  mobileBorder,
  headerTitle,
  headerFontSize,
  headerColor,
  headersTypes,
  headerFontFamily,
  headerFontStyle,
  headerFontWeight,
  contentFontFamily,
  contentFontStyle,
  contentFontWeight,
  contentItemGap,
  closedIconClassName,
  defaultState,
}: ContentTableProps) {
  const [opened, setOpened] = useState(false)
  const headersWithTypes = getHeadersWithTypes(
    headers,
    headersTypes as HeadingTypeEnum[],
  )

  const rootHeaderType = headersWithTypes
    .map(headerWithType => headerWithType.headerType)
    .sort(compareHeaderTypes)[0]

  useEffect(() => {
    setOpened(defaultState)
  }, [defaultState])

  return (
    <ContentTableUi
      padding={padding}
      mobilePadding={mobilePadding}
      mobileBackgroundColor={mobileBackgroundColor}
      backgroundColor={backgroundColor}
      border={border}
      mobileBorder={mobileBorder}
      fontFamily={contentFontFamily}
      fontStyle={contentFontStyle}
      fontWeight={contentFontWeight}
    >
      <ContentTableHeaderUi>
        <ContentTableHeaderTitleUi
          fontSize={headerFontSize}
          color={headerColor}
          fontFamily={headerFontFamily}
          fontStyle={headerFontStyle}
          fontWeight={headerFontWeight}
        >
          {headerTitle}
        </ContentTableHeaderTitleUi>
        <ContentTableHeaderIconWrapperUi>
          <ContentTableHeaderIconUi
            className={
              closedIconClassName
                ? opened
                  ? closedIconClassName
                  : iconClassName
                : iconClassName
            }
            color={iconColor}
            onClick={() => setOpened(!opened)}
            closedIcon={!!closedIconClassName}
            fontSize={headerFontSize}
            opened={opened}
          />
        </ContentTableHeaderIconWrapperUi>
      </ContentTableHeaderUi>
      <ContentTableContentUi collapsed={opened} fontSize={contentFontSize}>
        {headersWithTypes.map(headingWithType => (
          <ContentItemWrapperUi
            key={headingWithType.header.id}
            marginBottom={contentItemGap}
            marginLeft={getOffset(headingWithType, rootHeaderType)}
          >
            <ContentItemUi
              color={contentColor}
              href={`#${
                'rawContentState' in headingWithType.header
                  ? headingWithType.header.htmlAttrId
                  : headingWithType.header.options.attrId
              }`}
            >
              {getText(headingWithType.header)}
            </ContentItemUi>
          </ContentItemWrapperUi>
        ))}
      </ContentTableContentUi>
    </ContentTableUi>
  )
}

export default ContentTable
