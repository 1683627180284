import styled, { css } from 'styled-components'
import { BorderType } from '../../../../types/styleTypes'
import {
  backgroundCss,
  borderCss,
  mobileBackgroundCss,
  mobileBorderCss,
} from '../../../../utils/styleUtilsNew'

type ImageBackgroundUiProps = {
  width?: number
  mobileWidth?: number | string
  background?: string
  mobileBackground?: string
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
}

const ImageBackgroundUi = styled.div<ImageBackgroundUiProps>`
  max-width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  object-fit: contain; // keep proportions
  width: ${p => (p.width ? `${p.width}px` : '100%')};
  ${borderCss}
  ${backgroundCss}
  ${p => p.theme.phone} {
    ${mobileBackgroundCss};
    ${mobileBorderCss}
    ${p =>
      p.mobileWidth &&
      `width: ${
        typeof p.mobileWidth === 'string' ? p.mobileWidth : `${p.mobileWidth}px`
      }`}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileBackgroundCss};
      ${mobileBorderCss}
      ${p.mobileWidth &&
      `width: ${
        typeof p.mobileWidth === 'string' ? p.mobileWidth : `${p.mobileWidth}px`
      }`}
    `}
`

export default ImageBackgroundUi
