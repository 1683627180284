import md5 from 'md5'
import * as entityUtils from 'tools/utils/entityUtils'
import { localeValues } from 'common/constants/localeTypes'
import { pageTypeNode } from 'common/constants/pageTypes'
import { buttonActionsTypes } from 'common/constants/settings'
import structureTypes from 'common/constants/structureTypes'
import {
  entitiesWithFontOptions,
  getClearEntityWithCustomFontProperties,
} from 'common/utils/fontsUtils'
import { getReadableRootEntityTypeByPageType } from '../../common/enums/entityTypeEnum'
import {
  ADD_CHILD,
  ADD_STRUCTURE_ASCENDANT_AS_CHILD,
  CHANGE_PAGE_LOCALE,
  CREATE_DESTINATION_STRUCTURE,
  CREATE_ENTITY,
  CREATE_POPUP_STRUCTURE,
  CREATE_STRUCTURE,
  EXIT,
  FAIL,
  FETCH_PAGE,
  INCREMENT_FORM_COUNT,
  MARK_AS_MASTER_BLOCK,
  MOVE_DOWN,
  MOVE_NODE,
  MOVE_NODE_NEW,
  MOVE_UP,
  PAGE_VALIDATION_ERROR,
  PAGE_VALIDATION_ERROR_RESET,
  PREVIEW_PAGE,
  REMOVE_CHILD,
  REMOVE_FILE,
  REMOVE_LOST_ENTITIES,
  REMOVE_NODE,
  REMOVE_POPUP,
  RESET_PAGE_ERROR,
  SAVE_PAGE,
  START,
  SUCCESS,
  TOGGLE_DOUBLE_OPT_IN,
  TOGGLE_HIGHLIGHT,
  UPDATE_ENTITY,
  UPDATE_GLOBAL_SETTING,
  UPDATE_SEO,
  UPDATE_SEO_PROPERTY,
  UPDATE_TRACKING,
  UPDATE_TRACKING_PROPERTY,
} from '../actionTypes'
import {
  ADD_STRUCTURE,
  REMOVE_ENTITY_BY_ID_AND_PARENT_ID,
  UPDATE_GLOBAL_SETTINGS,
  UPDATE_PAGE_FROM_DB,
} from '../store/page/pageActionTypes'

const defaultReducerState = {
  entities: {},
  error: null,
  validationErrors: [],
  highlightedId: null,
  lastPopupNumber: 0,
  lastFormNumber: 0,
  locale: localeValues.fr,
  hash: '',
  seo: {
    title: '',
    description: '',
    keywords: '',
    author: '',
    socialImage: null,
  },
  tracking: {
    facebookPixelId: '',
    headerCode: '',
    footerCode: '',
  },
  globalSettings: {},
  parentGlobalSettings: {},
  isUnsaved: false,
  globalColor: null,
  isTemplate: false,
  doubleOptIn: false,
  id: null,
}

export const getAllDescendantIds = (state, nodeId) => {
  if (!state.entities[nodeId] || !state.entities[nodeId].childIds) {
    return []
  }

  return state.entities[nodeId].childIds.reduce(
    (prev, childId) => [
      ...prev,
      childId,
      ...getAllDescendantIds(state, childId),
    ],
    [],
  )
}

export const getDescendantIdsByIds = (state, ids) => {
  ids.reduce(
    (prev, id) => ({
      ...prev,
      [id]: getAllDescendantIds(state.page.present, id),
    }),
    {},
  )
}

const removeChildId = (childIds, id) =>
  childIds.filter(childId => childId !== id)

const removeEntitiesByIds = (entities, ids) => {
  const newEntities = { ...entities }
  ids.forEach(id => delete newEntities[id])
  return newEntities
}

const addChild = (childIds, id, position) => [
  ...childIds.slice(0, position),
  id,
  ...childIds.slice(position),
]

const removeNode = (state, payload) => {
  const descendantIds = getAllDescendantIds(state, payload.id)
  return {
    ...state,
    entities: removeEntitiesByIds(state.entities, [
      payload.id,
      ...descendantIds,
    ]),
    isUnsaved: true,
  }
}

const removeChild = (state, payload) => {
  const parentEntity = state.entities[payload.parentId]
  return {
    ...state.entities,
    [payload.parentId]: {
      ...parentEntity,
      childIds: removeChildId(parentEntity.childIds, payload.id),
    },
  }
}

const removePossibleFileUsageFromEntity = (entity, fileId) => {
  if (
    entity.options &&
    entity.options.srcFileId &&
    entity.options.srcFileId === fileId
  ) {
    return {
      ...entity,
      options: {
        ...entity.options,
        srcFileId: '',
      },
    }
  }

  if (
    entity.options &&
    entity.options.videoFileId &&
    entity.options.videoFileId === fileId
  ) {
    return {
      ...entity,
      options: {
        ...entity.options,
        videoFileId: '',
      },
    }
  }

  if (
    entity.options &&
    entity.options.backgroundFileId &&
    entity.options.backgroundFileId === fileId
  ) {
    return {
      ...entity,
      options: {
        ...entity.options,
        backgroundFileId: '',
      },
    }
  }

  return getClearEntityWithCustomFontProperties(entity, fileId)
}

const removePossibleFileUsageFromSeo = (seo, fileId) => {
  if (seo.socialImage && seo.socialImage === fileId) {
    return {
      ...seo,
      socialImage: '',
    }
  }

  return seo
}

const removeFile = (state, fileId) => {
  const entities = Object.values(state.entities).reduce((acc, entity) => {
    return {
      ...acc,
      [entity.id]: removePossibleFileUsageFromEntity(entity, fileId),
    }
  }, {})

  const seo = removePossibleFileUsageFromSeo(state.seo, fileId)

  return {
    ...state,
    entities,
    seo,
  }
}

const moveNode = (entities, moveId, fromId, toId, position) => {
  if (toId === fromId) {
    const entity = entities[toId]
    const newPosition =
      entity.childIds.findIndex(id => id === moveId) < position
        ? position - 1
        : position

    let childIds = removeChildId(entity.childIds, moveId)
    childIds = addChild(childIds, moveId, newPosition)

    return {
      ...entities,
      [toId]: {
        ...entity,
        childIds,
      },
    }
  }

  const newFromEntity = {
    ...entities[fromId],
    childIds: removeChildId(entities[fromId].childIds, moveId),
  }

  const newToEntity = {
    ...entities[toId],
    childIds: addChild(entities[toId].childIds, moveId, position),
  }

  const movableEntityWithNewParentId = {
    ...entities[moveId],
    parentId: toId,
  }

  return {
    ...entities,
    [fromId]: newFromEntity,
    [toId]: newToEntity,
    [moveId]: movableEntityWithNewParentId,
  }
}

const moveNodeNew = (entities, entity, toId, position) => {
  // if we move into the same position
  // debugger
  if (toId === entity.parentId) {
    const newParent = entities[toId]
    const newPosition =
      newParent.childIds.findIndex(id => id === entity.id) < position
        ? position - 1
        : position

    let childIds = removeChildId(newParent.childIds, entity.id)
    childIds = addChild(childIds, entity.id, newPosition)

    return {
      ...entities,
      [toId]: {
        ...newParent,
        childIds,
      },
    }
  }

  const oldParentEntity = {
    ...entities[entity.parentId],
    childIds: removeChildId(entities[entity.parentId].childIds, entity.id),
  }

  const newParentEntity = {
    ...entities[toId],
    childIds: addChild(entities[toId].childIds, entity.id, position),
  }

  const movableEntity = {
    ...entity,
    parentId: toId,
  }

  return {
    ...entities,
    [oldParentEntity.id]: oldParentEntity,
    [toId]: newParentEntity,
    [movableEntity.id]: movableEntity,
  }
}

function moveDown(entities, entity) {
  const position = getEntityPosition(entities, entity)
  return moveNodeNew(entities, entity, entity.parentId, position + 2)
}

function moveUp(entities, entity) {
  const position = getEntityPosition(entities, entity)
  return moveNodeNew(entities, entity, entity.parentId, position - 1)
}

export function getEntityParents(entities, targetEntity) {
  return Object.values(entities)
    .filter(entity => entity.parentId && entity.id === targetEntity.id)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
        ...(cur.parentId && entities[cur.parentId]
          ? getEntityParents(entities, entities[cur.parentId])
          : null),
      }),
      {},
    )
}

export function getEntityPosition(entities, entity) {
  const parent = entities[entity.parentId]
  return parent.childIds.findIndex(childId => childId === entity.id)
}

export function getEntityAncestorByType(entities, targetEntity, type) {
  return Object.values(getEntityParents(entities, targetEntity)).find(
    entity => entity.type === type,
  )
}

export const getAllDescendants = (state, nodeId) => {
  if (!state.entities[nodeId] || !state.entities[nodeId].childIds) {
    return []
  }

  return state.entities[nodeId].childIds.reduce(
    (prev, childId) => [
      ...prev,
      state.entities[childId],
      ...getAllDescendants(state, childId),
    ],
    [],
  )
}

function markAsMasterBlock(entities, masterBlockRootEntity, masterBlockId) {
  const descendants = entityUtils.getAllDescendants(
    entities,
    masterBlockRootEntity.id,
  )
  const masterBlockRootDescendantsStructure = descendants.reduce(
    (acc, entity) => ({
      ...acc,
      [entity.id]: { ...entity, masterBlockId },
    }),
    {},
  )

  return {
    [masterBlockRootEntity.id]: {
      ...masterBlockRootEntity,
      isMasterBlockRoot: true,
      masterBlockId,
    },
    ...masterBlockRootDescendantsStructure,
  }
}

export function pageReducer(state = defaultReducerState, action) {
  const { type, payload } = action

  switch (type) {
    case CREATE_STRUCTURE:
    case CREATE_DESTINATION_STRUCTURE:
    case CREATE_ENTITY:
    case ADD_STRUCTURE:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload,
        },
        isUnsaved: true,
      }
    case REMOVE_CHILD: {
      return {
        ...state,
        entities: removeChild(state, payload),
        isUnsaved: true,
      }
    }
    case REMOVE_ENTITY_BY_ID_AND_PARENT_ID:
    case REMOVE_NODE: {
      const entities = removeChild(state, payload)
      return removeNode({ ...state, entities }, payload)
    }
    case REMOVE_FILE: {
      return removeFile(state, payload)
    }
    case REMOVE_POPUP:
      return removeNode(state, payload)
    case REMOVE_LOST_ENTITIES:
      return {
        ...state,
        entities: removeEntitiesByIds(state.entities, payload),
      }
    case UPDATE_ENTITY:
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: payload,
        },
        isUnsaved: true,
      }
    case ADD_STRUCTURE_ASCENDANT_AS_CHILD:
    case ADD_CHILD: {
      const childIds = state.entities[payload.toId].childIds

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.toId]: {
            ...state.entities[payload.toId],
            childIds: addChild(childIds, payload.id, payload.position),
          },
        },
        isUnsaved: true,
      }
    }
    case CREATE_POPUP_STRUCTURE: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload,
        },
        lastPopupNumber: state.lastPopupNumber + 1,
      }
    }
    case INCREMENT_FORM_COUNT: {
      return {
        ...state,
        lastFormNumber: state.lastFormNumber + 1,
      }
    }
    case MOVE_NODE: {
      const { id, fromId, toId, position } = payload

      return {
        ...state,
        entities: moveNode(state.entities, id, fromId, toId, position),
        isUnsaved: true,
      }
    }
    case MOVE_NODE_NEW: {
      const { entity, toId, position, movableEntityDescendantsStructure } =
        payload

      return {
        ...state,
        entities: {
          ...moveNodeNew(state.entities, entity, toId, position),
          ...movableEntityDescendantsStructure,
        },
        isUnsaved: true,
      }
    }
    case MOVE_DOWN:
      return {
        ...state,
        entities: moveDown(state.entities, payload),
        isUnsaved: true,
      }
    case MOVE_UP:
      return {
        ...state,
        entities: moveUp(state.entities, payload),
        isUnsaved: true,
      }
    case MARK_AS_MASTER_BLOCK:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...markAsMasterBlock(
            state.entities,
            payload.masterBlockRootEntity,
            payload.masterBlockId,
          ),
        },
      }
    case TOGGLE_HIGHLIGHT:
      return {
        ...state,
        highlightedId: payload.id,
      }
    case FETCH_PAGE + START:
      return {
        ...state,
      }
    case FETCH_PAGE + SUCCESS:
      return {
        ...state,
        ...payload.content,
        id: payload.id,
        type: payload.type,
        hash: md5(JSON.stringify(payload.content.entities)),
        locale: payload.locale,
        isTemplate: payload.content.isTemplate,
      }
    case FETCH_PAGE + FAIL:
      return {
        ...state,
        error: payload,
      }
    case PREVIEW_PAGE + FAIL:
      return {
        ...state,
        error: payload,
      }
    case SAVE_PAGE:
      return {
        ...state,
        hash: md5(JSON.stringify(state.entities)),
        isUnsaved: false,
      }
    case SAVE_PAGE + FAIL:
      return {
        ...state,
        error: payload,
      }
    case PAGE_VALIDATION_ERROR:
      return {
        ...state,
        validationErrors: payload,
      }
    case PAGE_VALIDATION_ERROR_RESET:
      return {
        ...state,
        validationErrors: [],
      }
    case CHANGE_PAGE_LOCALE:
      return {
        ...state,
        locale: payload,
      }
    case UPDATE_SEO:
      return {
        ...state,
        seo: payload,
      }
    case UPDATE_SEO_PROPERTY:
      return {
        ...state,
        seo: {
          ...state.seo,
          [payload.property]: payload.value,
        },
      }
    case UPDATE_TRACKING:
      return {
        ...state,
        tracking: payload,
      }
    case UPDATE_TRACKING_PROPERTY:
      return {
        ...state,
        tracking: {
          ...state.tracking,
          [payload.property]: payload.value,
        },
      }
    case EXIT:
      return {
        ...state,
        isUnsaved: false,
      }
    case RESET_PAGE_ERROR:
      return {
        ...state,
        error: null,
      }
    case TOGGLE_DOUBLE_OPT_IN:
      return {
        ...state,
        doubleOptIn: !state.doubleOptIn,
      }
    case UPDATE_GLOBAL_SETTINGS:
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          ...payload,
        },
      }
    case UPDATE_GLOBAL_SETTING:
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          [payload.setting]: payload.value,
        },
      }
    case UPDATE_PAGE_FROM_DB: {
      return { ...state, ...payload }
    }
    default:
      return state
  }
}

export const getChildrenEntities = (state, childIds) =>
  childIds
    .filter(Boolean)
    .map(childId => state.entities[childId])
    .filter(Boolean)

export const getEntityById = ({ entities }, entityId) =>
  (!!entities[entityId] && entities[entityId]) || null

export const getIsUnsaved = ({ isUnsaved }) => isUnsaved

export const getByType = ({ entities }, type) =>
  Object.values(entities).filter(entity => entity.type === type)

export const getPopups = ({ entities }) =>
  Object.values(entities).filter(entity => entity.type === structureTypes.POPUP)

export const getNextPopupNumber = ({ lastPopupNumber }) => lastPopupNumber + 1

export const getRootEntity = ({ entities, type }) => {
  return Object.values(entities).find(
    entity => entity.type === pageTypeNode[type],
  )
}

export const getReadableRootEntity = ({ entities, type }) => {
  return Object.values(entities).find(
    entity => entity.type === getReadableRootEntityTypeByPageType(type),
  )
}

export const isPageModified = ({ entities, hash }) =>
  md5(JSON.stringify(entities)) !== hash

export const isTemplate = state => state.isTemplate

export const findDescendantsByType = (state, ascendantId, type) =>
  getAllDescendants(state, ascendantId).filter(entity => entity.type === type)

export const getUsedMasterBlockIds = ({ entities }) =>
  Object.values(entities)
    .filter(entity => Boolean(entity.masterBlockId))
    .map(entity => entity.masterBlockId)
    .filter((v, i, a) => a.indexOf(v) === i)

export const getLocale = ({ locale }) => locale

export const getPageId = state => state.id

export const getPageType = ({ type }) => type

export const getPageLocale = ({ locale }) => locale

export const getValidationErrors = ({ validationErrors }) => validationErrors

export const getError = ({ error }) => error

export const getBreadCrumbs = ({ entities }, id) =>
  id && entities[id] ? getEntityParents(entities, entities[id]) : {}

export const getDoubleOptIn = ({ doubleOptIn }) => doubleOptIn

export const getTracking = state => state.tracking

export const getSeo = state => state.seo

export const getGlobalSettings = ({ globalSettings }) => globalSettings

export const getParentGlobalSettings = ({ parentGlobalSettings }) =>
  parentGlobalSettings

export const getGlobalLinkColor = ({ globalSettings, parentGlobalSettings }) =>
  globalSettings.linkColor || parentGlobalSettings.linkColor

export const getGlobalTextColor = ({ globalSettings, parentGlobalSettings }) =>
  globalSettings.textColor || parentGlobalSettings.textColor

export const getGlobalFontFamily = ({ globalSettings, parentGlobalSettings }) =>
  globalSettings.fontFamily || parentGlobalSettings.fontFamily

export const getGlobalTextFontSize = ({
  globalSettings,
  parentGlobalSettings,
}) => globalSettings.textFontSize || parentGlobalSettings.textFontSize

export const getGlobalTextLineHeight = ({
  globalSettings,
  parentGlobalSettings,
}) => globalSettings.textLineHeight || parentGlobalSettings.textLineHeight

export const getGlobalMobileTextLineHeight = ({
  globalSettings,
  parentGlobalSettings,
}) =>
  globalSettings.mobileTextLineHeight ||
  parentGlobalSettings.mobileTextLineHeight

export const getGlobalMobileTextFontSize = ({
  globalSettings,
  parentGlobalSettings,
}) =>
  parentGlobalSettings.mobileTextFontSize || globalSettings.mobileTextFontSize

export const getGlobalHeadingFontSize = ({
  globalSettings,
  parentGlobalSettings,
}) => parentGlobalSettings.headingFontSize || globalSettings.headingFontSize

// entity related
export const getId = entity => entity.id

export const getOptionPopup = entity => entity.options.popup

export const getFieldSlug = entity => entity.options.slug

// entity predicates
export const hasNoAutoLoad = entity =>
  entity.options.openAutomatically !== 1 &&
  entity.options.openOnExit !== 1 &&
  entity.options.openOnMobileAutomatically

export const hasSendFormAction = entity =>
  entity.options.action === buttonActionsTypes.sendForm

export const hasOpenPopupAction = entity =>
  entity.options.action === buttonActionsTypes.showPopup &&
  !!entity.options.popup

export const isInput = entity => entity.type === structureTypes.FORM_INPUT

export const isButton = entity => entity.type === structureTypes.BUTTON

export const findFileId = entity => {
  if (entity.fileId) {
    return entity.fileId
  }

  if (entity.backgroundFileId) {
    return entity.backgroundFileId
  }

  if (entity.options && entity.options.backgroundFileId) {
    return entity.options.backgroundFileId
  }

  if (entity.options && entity.options.srcFileId) {
    return entity.options.srcFileId
  }

  if (entity.options && entity.options.videoFileId) {
    return entity.options.videoFileId
  }

  return null
}

export const addMasterBlockId = masterBlockId => entity => ({
  ...entity,
  masterBlockId,
})

export const removeMasterBlockId = entityWithMasterBlockId => {
  const { masterBlockId, ...entity } = entityWithMasterBlockId

  return entity
}

export const removeIsMasterBlockRoot = entity => {
  const { isMasterBlockRoot, ...entityWithoutIsMasterBlockRoot } = entity

  return entityWithoutIsMasterBlockRoot
}

export const getEntitiesWithFonts = ({ entities }) => {
  return Object.values(entities).filter(entity =>
    entitiesWithFontOptions.includes(entity.type),
  )
}

export const getAllPageEntities = ({ entities }) => {
  return entities
}

export const selectors = {
  getChildrenEntities,
  isPageModified,
  getAllDescendantIds,
  getGlobalFontFamily,
  getEntitiesWithFonts,
  isTemplate,
  getAllDescendants,
  getEntityById,
  getPageId,
  getPageLocale,
  getEntitiesByType: getByType,
  getPopups,
  getPageType,
  getNextPopupNumber,
  getTracking,
  getParentGlobalSettings,
  getUsedMasterBlockIds,
  getRootEntity,
  getAllPageEntities,
}
