import React from 'react'
import { useTranslation } from 'react-i18next'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonBreadcrumbs from 'common/components/entities/Breadcrumbs'
import {
  Breadcrumb,
  BreadcrumbsInterface,
} from 'common/types/entities/BreadcrumbsInterface'
import useBlog, { selectors } from 'publisher/hooks/useBlog'

function Breadcrumbs({ entity }: { entity: BreadcrumbsInterface }) {
  // @ts-ignore
  const postCategories = useBlog(selectors.getBlogPostCategories)
  const { t } = useTranslation('common')
  // @ts-ignore
  const blogPath: string = useBlog(selectors.getBlogPath)
  // @ts-ignore
  const blogPostTitle: string = useBlog(selectors.getBlogPostTitle)

  const breadcrumbs: Breadcrumb[] = [
    {
      name: t('components.breadcrumbs.items.home'),
      url: blogPath || '/',
    },
  ]

  if (postCategories.length > 0) {
    if (typeof postCategories[0] === 'object') {
      breadcrumbs.push({
        name: postCategories[0].name,
        url: `${blogPath}/category/${postCategories[0].path}`,
      })
    } else {
      breadcrumbs.push({
        name: postCategories[0],
        url: `${blogPath}/posts?category=${postCategories[0]}`,
      })
    }
  }

  if (blogPostTitle) {
    breadcrumbs.push({
      name: blogPostTitle,
    })
  }

  if (typeof window === 'undefined') {
    return (
      <StyleWrapper
        mobile={entity.appearance.mobile}
        desktop={entity.appearance.desktop}
        styles={entity.margin}
        mobileStyles={entity.mobileMargin}
      />
    )
  }

  return (
    <StyleWrapper
      mobile={entity.appearance.mobile}
      desktop={entity.appearance.desktop}
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
    >
      <CommonBreadcrumbs
        items={breadcrumbs}
        itemColor={entity.itemColor}
        lastItemColor={entity.lastItemColor}
        fontSize={entity.fontSize}
        fontStyle={entity.fontStyle}
        fontWeight={entity.fontWeight}
        fontFamily={entity.fontFamily}
        mobileFontSize={entity.mobileFontSize}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontWeight={entity.mobileFontWeight}
        mobileFontFamily={entity.mobileFontFamily}
      />
    </StyleWrapper>
  )
}

export default Breadcrumbs
