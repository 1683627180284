import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AlertUi from './ui/AlertUi'
import CloseUi from './ui/CloseUi'

function Alert({ type, children }) {
  const [isVisible, setVisible] = useState(true)

  const toggleShow = () => setVisible(!isVisible)

  return (
    isVisible && (
      <AlertUi type={type}>
        {children}
        <CloseUi onClick={toggleShow}>&times;</CloseUi>
      </AlertUi>
    )
  )
}

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
}

Alert.defaultProps = {
  type: 'warning',
}

export default Alert
