import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonContentTable from 'common/components/entities/ContentTable'
import { ContentTableInterface } from 'common/types/entities/ContentTableInterface'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import useManagement, {
  selectors as managementSelectors,
} from '../../hooks/useManagement'

function ContentTable({ entity }: { entity: ContentTableInterface }) {
  const isDesktop = useManagement(managementSelectors.isDesktop)
  const headings = usePage(page =>
    pageSelectors.getOrderedByPositionHeadings(page, !isDesktop),
  )

  const globalFontFamily = usePage(pageSelectors.getGlobalTextFontFamily)

  if (typeof window === 'undefined') {
    return (
      <StyleWrapper
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
        styles={entity.margin}
        mobileStyles={entity.mobileMargin}
      />
    )
  }

  return (
    <StyleWrapper
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonContentTable
        iconClassName={entity.iconClassName}
        closedIconClassName={
          entity.closedIconClassName && entity.closedIconClassName
        }
        defaultState={
          typeof entity.isOpenedByDefault === 'boolean'
            ? !entity.isOpenedByDefault
            : false
        }
        iconColor={entity.iconColor}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        headers={headings}
        headersTypes={entity.headersTypes}
        headerFontFamily={entity.headerFontFamily || globalFontFamily}
        headerFontStyle={entity.headerFontStyle}
        headerFontWeight={entity.headerFontWeight}
        contentColor={entity.contentColor}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        headerTitle={entity.headerTitle}
        headerFontSize={entity.headerFontSize}
        headerColor={entity.headerColor}
        contentFontSize={entity.contentFontSize}
        contentFontFamily={entity.contentFontFamily}
        contentFontStyle={entity.contentFontStyle}
        contentFontWeight={entity.contentFontWeight}
        contentItemGap={entity.contentItemGap}
      />
    </StyleWrapper>
  )
}

export default ContentTable
