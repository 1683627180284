import { useEffect, useCallback } from 'react'

const LOG_EVENT_NAME = 'sessionLogStarted'

export function useSessionLogEvent<T>(handler: <T>(data: T) => void) {
  const handleExternalEvent = useCallback(
    (event: CustomEvent<T>) => handler(event.detail),
    [],
  )

  useEffect(() => {
    // @ts-ignore
    window.document.addEventListener(LOG_EVENT_NAME, handleExternalEvent)

    return () =>
      // @ts-ignore
      window.document.removeEventListener(LOG_EVENT_NAME, handleExternalEvent)
  }, [handleExternalEvent])
}
