import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as exceptions from 'common/errors'
import * as api from 'publisher/api/memberAccess'
import { optInFail } from '../actions/optInActions'
import { validateEmptyOptInFields } from '../utils/fieldValidation'
import useManagement, { selectors as managementSel } from './useManagement'
import useOptIn, { selectors as optInSelectors } from './useOptIn'

function useLoginSubmit(button) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const fields = useOptIn(optInSelectors.getCustomerOptInFields)
  const confirmedExplicitConsentsIds = useManagement(
    managementSel.getConfirmedExplicitConsentsIds,
  ).length
  const rememberMe = confirmedExplicitConsentsIds > 0 ? 'on' : 'off'
  const csrfToken = useOptIn(optInSelectors.getCsrfToken)
  const calculatedEmail = fields.email ? fields.email : ''
  const calculatedPassword = fields.password ? fields.password : ''

  let formData
  switch (button.options.action) {
    case 'login':
      formData = {
        email: calculatedEmail,
        password: calculatedPassword,
        _remember_me: rememberMe,
        _csrf_token: csrfToken,
      }
      break
    case 'confirmRegistration':
      formData = {
        plainPassword: {
          first: calculatedPassword,
          second: fields.confirm_password,
        },
        _csrf_token: csrfToken,
      }
      break
    default:
      formData = {
        email: calculatedEmail,
        _csrf_token: csrfToken,
      }
      break
  }

  const submit = async () => {
    const slugs = Object.keys(fields)
    const fieldsForValidationFormat = slugs.reduce((acc, slug) => {
      return { ...acc, [slug]: { value: fields[slug] } }
    }, {})
    const emptyFieldErrors = validateEmptyOptInFields(
      fieldsForValidationFormat,
      t,
    )
    if (Object.keys(emptyFieldErrors).length) {
      const common = []
      dispatch(optInFail({ common, fields: emptyFieldErrors }))
      return
    }
    setLoading(true)
    try {
      const response = await api.sendForm(formData)
      window.location.replace(response.data.redirect)
    } catch (error) {
      if (error instanceof exceptions.BadRequest) {
        setErrors(error.response.data.errors.common)
        dispatch(optInFail({ fields: error.response.data.errors.fields }))
        window.dispatchEvent(new Event('form_updated'))
      } else if (error instanceof exceptions.NetworkError) {
        setErrors([t('core.errors.no_connection')])
      } else {
        Rollbar.error(`Custom login error`, error)
      }
    }
    setLoading(false)
  }

  return [isLoading, errors, submit]
}

export default useLoginSubmit
