const fonts = {
  inherited: [],
  safe: [
    'Georgia, serif',
    '"Times New Roman", Times, serif',
    'Arial, Helvetica, sans-serif',
    '"Arial Black", Gadget, sans-serif',
    '"Comic Sans MS",Chalkboard, sans-serif, cursive',
    '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
    'Tahoma, Geneva, sans-serif',
    '"Trebuchet MS", Helvetica, sans-serif',
    'Verdana, Geneva, sans-serif',
    '"Courier New", Courier, monospace',
    '"Lucida Console", Monaco, monospace',
    'AvertaPE',
  ],
  google: [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Lato',
    'Poppins',
    'Source Sans Pro',
    'Roboto Condensed',
    'Oswald',
    'Roboto Mono',
    'Inter',
    'Noto Sans',
    'Raleway',
    'Ubuntu',
    'Nunito Sans',
    'Roboto Slab',
    'Nunito',
    'Playfair Display',
    'Merriweather',
    'Rubik',
    'PT Sans',
    'Kanit',
    'Work Sans',
    'Lora',
    'Fira Sans',
    'Quicksand',
    'Mulish',
    'Inconsolata',
    'Titillium Web',
    'PT Serif',
    'Heebo',
    'Noto Serif',
    'Libre Franklin',
    'Karla',
    'Josefin Sans',
    'Hind Siliguri',
    'Arimo',
    'Dosis',
    'Libre Baskerville',
    'PT Sans Narrow',
    'Bitter',
    'Source Serif Pro',
    'Source Code Pro',
    'Anton',
    'Oxygen',
    'Cabin',
    'Cairo',
    'Catamaran',
    'Abel',
    'Prompt',
    'Lobster',
    'Dancing Script',
    'EB Garamond',
    'Signika Negative',
    'Hind Madurai',
    'Varela Round',
    'Comfortaa',
    'Pacifico',
    'Secular One',
    'Fjalla One',
    'Hind',
    'Exo 2',
    'Maven Pro',
    'Crimson Text',
    'Arvo',
    'Teko',
    'Cormorant Garamond',
    'Merriweather Sans',
    'Asap',
    'Assistant',
    'Abril Fatface',
    'Caveat',
    'Rajdhani',
    'Shadows Into Light',
    'Righteous',
    'Yanone Kaffeesatz',
    'Satisfy',
    'Fira Sans Condensed',
    'Slabo 27px',
    'Overpass',
    'Indie Flower',
    'Fredoka One',
    'Signika',
    'Zilla Slab',
    'Play',
    'Exo',
    'Vollkorn',
    'Questrial',
    'Lilita One',
    'Permanent Marker',
    'Domine',
    'Acme',
    'Bree Serif',
    'Archivo Narrow',
    'Amatic SC',
    'Alegreya Sans',
    'Russo One',
    'Didact Gothic',
    'Alfa Slab One',
    'Orbitron',
    'ABeeZee',
    'Kalam',
    'Cinzel',
    'Archivo Black',
    'Tinos',
    'Zeyada',
    'Space Mono',
    'Amiri',
    'Frank Ruhl Libre',
    'Alegreya',
    'Noticia Text',
    'Changa',
    'Lobster Two',
    'Spectral',
    'Courgette',
    'Ubuntu Condensed',
    'Martel',
    'Great Vibes',
    'Yantramanav',
    'Cormorant',
    'Patua One',
    'Crete Round',
    'Rokkitt',
    'PT Sans Caption',
    'Montserrat Alternates',
    'Prata',
    'Titan One',
    'Cardo',
    'Francois One',
    'Fira Sans Extra Condensed',
    'Passion One',
    'Yellowtail',
    'Cookie',
    'Kaushan Script',
    'Old Standard TT',
    'Eczar',
    'Michroma',
    'Pathway Gothic One',
    'Philosopher',
    'Gloria Hallelujah',
    'Macondo',
    'Marcellus',
    'Antic Slab',
    'Cantarell',
    'Paytone One',
    'PT Mono',
    'Press Start 2P',
    'Concert One',
    'Carter One',
    'Unna',
    'Creepster',
    'Patrick Hand',
    'Sacramento',
    'Quattrocento Sans',
    'News Cycle',
    'Itim',
    'Chivo',
    'Luckiest Guy',
    'El Messiri',
    'Monda',
    'Khand',
    'Alice',
    'Josefin Slab',
    'Cuprum',
    'Quattrocento',
    'Ubuntu Mono',
    'Advent Pro',
    'Mr Dafoe',
    'Ropa Sans',
    'Handlee',
    'Marck Script',
    'Yeseva One',
    'Mitr',
    'Neucha',
    'Playfair Display SC',
    'Special Elite',
    'Alegreya Sans SC',
    'Poiret One',
    'Sanchez',
    'Vidaloka',
    'Arsenal',
    'Bangers',
    'Neuton',
    'Oleo Script',
    'Ultra',
    'Fugaz One',
    'Architects Daughter',
    'Tenor Sans',
    'Allura',
    'Rubik Mono One',
    'Volkhov',
    'Ruda',
    'Mada',
    'Tangerine',
    'Taviraj',
    'Merienda',
    'Parisienne',
    'Viga',
    'Shrikhand',
    'Padauk',
    'Stint Ultra Condensed',
    'Gruppo',
    'Ramabhadra',
    'Gudea',
    'Playball',
    'Hind Vadodara',
    'Amaranth',
    'Homemade Apple',
    'Quantico',
    'Hammersmith One',
    'Actor',
    'Sriracha',
    'Istok Web',
    'Alex Brush',
    'Abhaya Libre',
    'Alef',
    'Cabin Condensed',
    'Unica One',
    'Khula',
    'Mandali',
    'Bad Script',
    'Allerta Stencil',
    'Audiowide',
    'Bungee',
    'Cousine',
    'Rock Salt',
    'Monoton',
    'BenchNine',
    'Sigmar One',
    'Economica',
    'Fira Mono',
    'Lusitana',
    'Black Ops One',
    'Damion',
    'Voltaire',
    'Julius Sans One',
    'Caveat Brush',
    'Pragati Narrow',
    'Adamina',
    'Laila',
    'Jura',
    'Lalezar',
    'Varela',
    'Sintony',
    'Sarala',
    'Pontano Sans',
    'Forum',
    'Rufina',
    'Six Caps',
    'Athiti',
    'Sorts Mill Goudy',
    'Nothing You Could Do',
    'Share Tech Mono',
    'Martel Sans',
    'Palanquin',
    'Pinyon Script',
    'Days One',
    'Jaldi',
    'Gentium Book Basic',
    'Karma',
    'Reenie Beanie',
    'Kreon',
    'Electrolize',
    'Covered By Your Grace',
    'Italianno',
    'Anonymous Pro',
    'Sansita',
    'Amita',
    'Cutive Mono',
    'VT323',
    'Squada One',
    'Wallpoet',
    'Pangolin',
    'Arapey',
    'Leckerli One',
    'Average Sans',
    'Hind Guntur',
    'Holtwood One SC',
    'Short Stack',
    'Chewy',
    'Londrina Solid',
    'Syncopate',
    'Gilda Display',
    'Armata',
    'Pridi',
    'Berkshire Swash',
    'Cabin Sketch',
    'Lustria',
    'Mrs Saint Delafield',
    'Koulen',
    'Yatra One',
    'Lemonada',
    'Racing Sans One',
    'Antic',
    'Aclonica',
    'Glegoo',
    'Yrsa',
    'Miriam Libre',
    'Suez One',
    'Shadows Into Light Two',
    'Basic',
    'Candal',
    'Cantata One',
    'Fredericka the Great',
    'Rancho',
    'PT Serif Caption',
    'Gochi Hand',
    'Changa One',
    'Boogaloo',
    'Coda',
    'Aldrich',
    'Graduate',
    'Arima Madurai',
    'Petrona',
    'Rozha One',
    'Herr Von Muellerhoff',
    'Quintessential',
    'Oranienbaum',
    'Julee',
    'Averia Serif Libre',
    'Knewave',
    'Palanquin Dark',
    'Rammetto One',
    'Bowlby One SC',
    'Reem Kufi',
    'Arizonia',
    'Cinzel Decorative',
    'Bevan',
    'Amethysta',
    'Rye',
    'Telex',
    'Annie Use Your Telescope',
    'Capriola',
    'La Belle Aurore',
    'Krona One',
    'Mate',
    'Enriqueta',
    'Average',
    'Marcellus SC',
    'GFS Didot',
    'Just Another Hand',
    'Kristi',
    'Corben',
    'Rambla',
    'BioRhyme',
    'Arbutus Slab',
    'Cedarville Cursive',
    'Belleza',
    'Norican',
    'Niconne',
    'David Libre',
    'Pattaya',
    'Sniglet',
    'Mallanna',
    'Cormorant Infant',
    'Contrail One',
    'Coustard',
    'Sofia',
    'Henny Penny',
    'Bellefair',
    'Allerta',
    'Alike',
    'Overlock',
    'Trocchi',
    'Kameron',
    'Trirong',
    'Coming Soon',
    'Chonburi',
    'Overpass Mono',
    'Biryani',
    'Nobile',
    'Bubblegum Sans',
    'Amiko',
    'Halant',
    'Carrois Gothic',
    'Petit Formal Script',
    'Averia Libre',
    'Bungee Inline',
    'Scada',
    'Rochester',
    'Schoolbell',
    'Alegreya SC',
    'Yesteryear',
    'Fjord One',
    'Lateef',
    'Grand Hotel',
    'NTR',
    'Mukta Vaani',
    'Magra',
    'Qwigley',
    'Patrick Hand SC',
    'Delius',
    'Aladin',
    'Seaweed Script',
    'Maitree',
    'Smokum',
    'Monsieur La Doulaise',
    'Skranji',
    'Ovo',
    'Caudex',
    'Spinnaker',
    'Nixie One',
    'Brawler',
    'Bowlby One',
    'Cambay',
    'Judson',
    'Marmelad',
    'Merienda One',
    'Fauna One',
    'Dawning of a New Day',
    'Irish Grover',
    'Kelly Slab',
    'Asul',
    'Kite One',
    'Proza Libre',
    'Rosario',
    'Podkova',
    'IM Fell English SC',
    'Mate SC',
    'Waiting for the Sunrise',
    'Jockey One',
    'Oxygen Mono',
    'Meera Inimai',
    'Rasa',
    'Stardos Stencil',
    'Copse',
    'Bungee Shade',
    'Marvel',
    'IM Fell English',
    'Molengo',
    'Calligraffitti',
    'Slabo 13px',
    'Bentham',
    'Gabriela',
    'Pirata One',
    'Limelight',
    'Kurale',
    'Fresca',
    'Cormorant SC',
    'Suranna',
    'Lemon',
    'Pompiere',
    'Share',
    'Oleo Script Swash Caps',
    'Poller One',
    'McLaren',
    'Hanuman',
    'Allan',
    'Love Ya Like A Sister',
    'Cutive',
    'Metrophobic',
    'Cormorant Upright',
    'Quando',
    'Fondamento',
    'Alike Angular',
    'Goudy Bookletter 1911',
    'UnifrakturMaguntia',
    'Andika',
    'Gurajada',
    'Rakkas',
    'Harmattan',
    'Baumans',
    'IM Fell DW Pica',
    'Homenaje',
    'Mirza',
    'Montserrat Subrayada',
    'Aref Ruqaa',
    'Vesper Libre',
    'Nova Mono',
    'Italiana',
    'Chelsea Market',
    'Montez',
    'Della Respira',
    'Fanwood Text',
    'Mouse Memoirs',
    'Geo',
    'Inder',
    'Kadwa',
    'Megrim',
    'Radley',
    'Gravitas One',
    'Sue Ellen Francisco',
    'Antic Didone',
    'Averia Sans Libre',
    'Cormorant Unicase',
    'Metamorphous',
    'Duru Sans',
    'Rouge Script',
    'Tenali Ramakrishna',
    'Meddon',
    'Mako',
    'Flamenco',
    'Carme',
    'Tillana',
    'IM Fell Double Pica',
    'Numans',
    'Mr De Haviland',
    'Codystar',
    'Emilys Candy',
    'Poly',
    'Raleway Dots',
    'Buenard',
    'Supermercado One',
    'Elsie',
    'Aguafina Script',
    'Overlock SC',
    'Arya',
    'Expletus Sans',
    'Oregano',
    'Over the Rainbow',
    'Give You Glory',
    'Convergence',
    'Happy Monkey',
    'Ceviche One',
    'Amarante',
    'Original Surfer',
    'Atma',
    'Bilbo Swash Caps',
    'Cambo',
    'Euphoria Script',
    'Federo',
    'Share Tech',
    'Trykker',
    'Just Me Again Down Here',
    'Montaga',
    'Clicker Script',
    'Jomhuria',
    'Vast Shadow',
    'Goblin One',
    'Lekton',
    'Eater',
    'Doppio One',
    'Ledger',
    'Anaheim',
    'Mountains of Christmas',
    'Sail',
    'Freckle Face',
    'Modak',
    'Slackey',
    'Finger Paint',
    'Belgrano',
    'Coiny',
    'Ruslan Display',
    'Galada',
    'Walter Turncoat',
    'Esteban',
    'Bubbler One',
    'Pavanam',
    'Chau Philomene One',
    'Wendy One',
    'Germania One',
    'Inknut Antiqua',
    'Almendra',
    'Orienta',
    'Shojumaru',
    'Salsa',
    'Delius Swash Caps',
    'Lily Script One',
    'Crafty Girls',
    'Timmana',
    'Nova Round',
    'Ranga',
    'Tienne',
    'Headland One',
    'Balthazar',
    'Battambang',
    'Life Savers',
    'Scope One',
    'Port Lligat Sans',
    'Port Lligat Slab',
    'Prosto One',
    'Faster One',
    'League Script',
    'Cherry Swash',
    'Dynalight',
    'Sarpanch',
    'Peralta',
    'Katibeh',
    'Spicy Rice',
    'Engagement',
    'Ruthie',
    'Vibur',
    'Loved by the King',
    'Ranchers',
    'Frijole',
    'Ramaraja',
    'MedievalSharp',
    'Cantora One',
    'Bigshot One',
    'Voces',
    'Artifika',
    'Denk One',
    'Imprima',
    'Tauri',
    'Kotta One',
    'Delius Unicase',
    'The Girl Next Door',
    'Medula One',
    'Kranky',
    'Cherry Cream Soda',
    'Gorditas',
    'Bayon',
    'Rum Raisin',
    'Sree Krushnadevaraya',
    'Chango',
    'Nova Square',
    'Sumana',
    'Puritan',
    'Averia Gruesa Libre',
    'Simonetta',
    'Gafata',
    'Prociono',
    'Londrina Shadow',
    'Londrina Outline',
    'Farsan',
    'Wire One',
    'Sirin Stencil',
    'Strait',
    'Uncial Antiqua',
    'Rationale',
    'Unlock',
    'Unkempt',
    'IM Fell French Canon',
    'Asar',
    'Iceland',
    'Stoke',
    'Carrois Gothic SC',
    'Shanti',
    'Sonsie One',
    'Mystery Quest',
    'Akronim',
    'Habibi',
    'Trade Winds',
    'Rosarivo',
    'Ribeye',
    'Lovers Quarrel',
    'Stint Ultra Expanded',
    'Dekko',
    'Barrio',
    'Miniver',
    'Nokora',
    'Monofett',
    'Paprika',
    'Piedra',
    'Macondo Swash Caps',
    'New Rocker',
    'Nova Flat',
    'Redressed',
    'Sancreek',
    'Donegal One',
    'Fascinate Inline',
    'Milonga',
    'Fontdiner Swanky',
    'Sarina',
    'Princess Sofia',
    'Stalemate',
    'Iceberg',
    'Chicle',
    'Bigelow Rules',
    'Vampiro One',
    'Wellfleet',
    'Kavivanar',
    'Sura',
    'Angkor',
    'Condiment',
    'Chathura',
    'Cagliostro',
    'Moul',
    'Crushed',
    'Meie Script',
    'Griffy',
    'IM Fell Great Primer',
    'Margarine',
    'Offside',
    'Kavoon',
    'Ravi Prakash',
    'Odor Mean Chey',
    'Metal Mania',
    'Dorsa',
    'Freehand',
    'Tulpen One',
    'Text Me One',
    'Underdog',
    'Marko One',
    'Ruluko',
    'Elsie Swash Caps',
    'Kumar One',
    'Jolly Lodger',
    'Risque',
    'Bilbo',
    'Eagle Lake',
    'Fenix',
    'Felipa',
    'Englebert',
    'Diplomata',
    'Autour One',
    'Keania One',
    'Modern Antiqua',
    'Galdeano',
    'Swanky and Moo Moo',
    'Nosifer',
    'Mrs Sheppards',
    'Seymour One',
    'Spirax',
    'Hanalei Fill',
    'Fascinate',
    'Joti One',
    'Junge',
    'Linden Hill',
    'Bahiana',
    'Romanesco',
    'Inika',
    'Croissant One',
    'Ewert',
    'Diplomata SC',
    'Rhodium Libre',
    'Ribeye Marrow',
    'Metal',
    'Oldenburg',
    'Jim Nightshade',
    'Caesar Dressing',
    'Maiden Orange',
    'Arbutus',
    'Mogra',
    'Chela One',
    'Revalia',
    'Lancelot',
    'IM Fell DW Pica SC',
    'Jacques Francois',
    'Lakki Reddy',
    'Emblema One',
    'Snippet',
    'Sahitya',
    'Bungee Hairline',
    'Devonshire',
    'IM Fell Great Primer SC',
    'Dangrek',
    'Galindo',
    'Glass Antiqua',
    'Almendra SC',
    'Jacques Francois Shadow',
    'IM Fell French Canon SC',
    'Gidugu',
    'Peddana',
    'Plaster',
    'Smythe',
    'Atomic Age',
    'Asset',
    'Flavors',
    'BioRhyme Expanded',
    'Almendra Display',
    'Bungee Outline',
    'Butterfly Kids',
    'Dr Sugiyama',
    'Nova Slim',
    'Miss Fajardose',
    'Erica One',
    'Passero One',
    'Combo',
    'Hanalei',
    'Trochut',
    'Ruge Boogie',
    'Mr Bedfort',
    'Preahvihear',
    'Astloch',
    'Stalinist One',
    'Suwannaphum',
    'Federant',
    'Bokor',
    'Butcherman',
    'Sofadi One',
    'Londrina Sketch',
    'Fruktur',
    'GFS Neohellenic',
    'Nova Cut',
    'Purple Purse',
    'IM Fell Double Pica SC',
    'Snowburst One',
    'Aubrey',
    'Bonbon',
    'Suravaram',
    'Sunshiney',
    'Nova Script',
    'Geostar Fill',
    'Miltonian',
    'Taprom',
    'Moulpali',
    'Geostar',
    'Fasthand',
    'Nova Oval',
    'Miltonian Tattoo',
    'Dhurjati',
    'Kenia',
    'Sevillana',
    'Warnes',
  ],
}

export default fonts
