import { ManagementState } from '../index'

function isEditing(state: ManagementState, entityId: string) {
  return state.editingEntityId === entityId
}

function getCustomLoginMode(state: ManagementState) {
  return state.customLoginMode
}

function isUserFreemium(state: ManagementState) {
  return state.user.isFreemium
}

function getEditingId(state: ManagementState) {
  return state.editingEntityId
}

function getActivePopupId(state: ManagementState) {
  return state.activePopupId
}

function isSideMenuOpened(state: ManagementState) {
  return state.sideMenuOpened
}

function isDropDownMenuOpened(state: ManagementState) {
  return state.dropDownMenuOpened
}

function isInstantUploadEnabled(state: ManagementState) {
  return state.instantUploadEnabled
}

function isCodeDialogOpened(state: ManagementState) {
  return state.isCodeDialogOpened
}

function isCodeDialogPortalAllowed(state: ManagementState) {
  return state.isCodeDialogPortalAllowed
}

function getUserName(state: ManagementState) {
  return state.user.name
}

function getUserId(state: ManagementState) {
  return state.user.id
}

function getUserTimezone(state: ManagementState) {
  return state.user.timezone
}

function getUserLocale(state: ManagementState) {
  return state.user.dashboardLocale
}

function hasFacebookConversionPixel(state: ManagementState) {
  return state.hasFacebookConversionPixel
}

const managementSelectors = {
  isEditing,
  isUserFreemium,
  getEditingId,
  getCustomLoginMode,
  getActivePopupId,
  isSideMenuOpened,
  isDropDownMenuOpened,
  isInstantUploadEnabled,
  isCodeDialogOpened,
  isCodeDialogPortalAllowed,
  getUserName,
  getUserTimezone,
  hasFacebookConversionPixel,
  getUserId,
  getUserLocale,
}

export default managementSelectors
