import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import useBlog from '../../hooks/useBlog'
import useManagement from '../../hooks/useManagement'
import usePage from '../../hooks/usePage'
import {
  getBlogPostImageUrl,
  getBlogPostTitle,
  getBlogPostDescrition,
} from '../../reducers/blogReducer'
import { getFile } from '../../reducers/filesReducer'
import { getUrl } from '../../reducers/managementReducer'
import { getSeo } from '../../reducers/pageReducer'

function Meta({ noIndex }) {
  const seo = usePage(getSeo)
  const file = useSelector(state => getFile(state.files, seo.socialImage))
  const blogPostImageUrl = useBlog(getBlogPostImageUrl)
  const blogPostTitle = useBlog(getBlogPostTitle)
  const blogPostDescription = useBlog(getBlogPostDescrition)
  const ogImagePath = (file && file.path) || blogPostImageUrl || ''
  const ogTitle = seo.title || blogPostTitle
  const currentDescription = seo.description || blogPostDescription
  const ogDescrition =
    currentDescription !== '%BLOG_POST_DESCRIPTION%' ? currentDescription : ''
  const url = useManagement(getUrl)

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="title" content={seo.title} />
      <meta name="description" content={seo.description} />
      <meta name="author" content={seo.author} />
      <meta name="keywords" content={seo.keywords} />
      <meta property="og:image" content={ogImagePath} />
      {ogImagePath && <meta property="og:image:alt" content={seo.title} />}
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescrition} />
      <meta property="og:url" content={url} />
      {noIndex && <meta name="robots" content="noindex" />}
      <meta name="format-detection" content="telephone=no" />
    </Helmet>
  )
}

Meta.propTypes = {
  noIndex: PropTypes.bool,
}

Meta.defaultProps = {
  noIndex: false,
}

export default Meta
