import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonTextArea from 'common/components/entities/TextArea'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { TextAreaInterface } from 'common/types/entities/TextAreaInterface'
import FieldErrors from '../../../components/FieldErrors'
import {
  ContextFieldsInterface,
  getContactUsError,
  useShowContactUsSuccess,
} from './ContactUs'

function TextArea({ entity }: { entity: TextAreaInterface }) {
  const { errors, setFields, fields } = useShowContactUsSuccess()
  const value = fields[FieldSlugEnum.Message] || ''

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = e.target
    setFields((prev: ContextFieldsInterface) => {
      return {
        ...prev,
        [FieldSlugEnum.Message]: value,
      }
    })
  }

  const contactUsTextAreaError = getContactUsError(
    errors,
    FieldSlugEnum.Message,
  )

  return (
    <StyleWrapper
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonTextArea
        onChange={handleChange}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
        placeholder={entity.placeholder}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        value={value}
        height={entity.height}
        id={entity.htmlAttrId}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        color={entity.color}
        mobileColor={entity.mobileColor}
      />
      {/*@ts-ignore*/}
      <FieldErrors errors={contactUsTextAreaError} align="center" />
    </StyleWrapper>
  )
}

export default TextArea
