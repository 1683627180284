import styled, { keyframes } from 'styled-components'

const bounceKeyFrames = keyframes`
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.99)
  }
`

const UnMute = styled.div`
  position: absolute;
  z-index: 2;
  cursor: pointer;
  height: 50px;
  width: 50px;
  top: 50px;
  left: 50px;
  background-size: cover;
  background-image: url(${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/webinar/unmute.png);
  animation: ${bounceKeyFrames} 0.2s infinite ease-in-out alternate;
`

export default UnMute
