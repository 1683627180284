import { useEffect, useLayoutEffect, useState } from 'react'
import { MenuItemInterface } from 'common/components/entities/Menu/types/MenuInterface'
import { hasSubMenu } from 'common/components/entities/Menu/utils/hasSubMenu'
import { isUrlCurrent } from 'common/utils/urlUtils'

export default function useActiveMenuItem(
  item: MenuItemInterface,
  index: number,
): [number | null, number | null] {
  const [activeIndex, setActiveIndex] = useState<
    [number | null, number | null]
  >([null, null])
  const currentUrl = useCurrentUrl()
  useLayoutEffect(() => {
    if (hasSubMenu(item)) {
      item.subMenuItems?.forEach((subItem, subIndex) => {
        if (isUrlCurrent(subItem.link, currentUrl)) {
          setActiveIndex([index, subIndex])
        }
      })
    } else if (isUrlCurrent(item.link, currentUrl)) {
      setActiveIndex([index, null])
    }
  }, [item, currentUrl, index])

  return activeIndex
}

const useCurrentUrl = () => {
  const [url, setUrl] = useState(
    typeof window === 'undefined' ? '' : window.location.href,
  )
  const listenToPopstate = () => {
    setUrl(window.location.href)
  }
  useEffect(() => {
    window.addEventListener('popstate', listenToPopstate)
    return () => {
      window.removeEventListener('popstate', listenToPopstate)
    }
  }, [])
  return url
}
