import React, { useEffect, useState } from 'react'
import Alert from 'common/components/core/Alert'
import usePage, { selectors as pageSelectors } from '../../hooks/usePage'
import {
  getNotificationCookie,
  removeNotificationCookie,
} from '../../utils/cookie'

function Notification() {
  const [notification, setNotification] = useState('')
  const pageId = usePage(pageSelectors.getPageId)

  useEffect(() => {
    const message = getNotificationCookie(pageId)
    if (message) {
      setNotification(message)
    }
    removeNotificationCookie(pageId)
  }, [])

  return notification ? <Alert type="error">{notification}</Alert> : null
}

export default Notification
