import React from 'react'
import { CommonButtonProps } from 'common/types/entities/ButtonInterface'
import ButtonContentUi from './ui/ButtonContentUi'
import ButtonIconUi from './ui/ButtonIconUi'
import ButtonUi from './ui/ButtonUi'
import SubTextUiNew from './ui/SubTextUiNew'

type ButtonProps = {
  attrId: string
  disabled?: boolean
  onClick?: () => void
} & CommonButtonProps

function Button({
  disabled,
  attrId,
  text,
  subText,
  padding,
  mobilePadding,
  alignSelf,
  mobileAlignSelf,
  width,
  mobileWidth,
  textFontSize,
  mobileTextFontSize,
  subTextFontSize,
  mobileSubTextFontSize,
  textFontFamily,
  textFontWeight,
  textFontStyle,
  subTextFontFamily,
  subTextFontWeight,
  subTextFontStyle,
  mobileTextFontFamily,
  mobileTextFontWeight,
  mobileTextFontStyle,
  mobileSubTextFontFamily,
  mobileSubTextFontWeight,
  mobileSubTextFontStyle,
  textColor,
  subTextColor,
  mobileTextColor,
  mobileSubTextColor,
  backgroundColor,
  mobileBackgroundColor,
  iconClassNameBefore,
  iconClassNameAfter,
  border,
  mobileBorder,
  boxShadow,
  mobileBoxShadow,
  onClick,
  lineHeight,
  mobileLineHeight,
  hover,
  mobileHover,
}: ButtonProps) {
  return (
    <ButtonUi
      id={attrId}
      border={border}
      mobileBorder={mobileBorder}
      color={textColor}
      fontFamily={textFontFamily}
      fontWeight={textFontWeight}
      fontStyle={textFontStyle}
      fontSize={textFontSize}
      mobileFontSize={mobileTextFontSize}
      mobileColor={mobileTextColor}
      mobileFontFamily={mobileTextFontFamily}
      mobileFontWeight={mobileTextFontWeight}
      mobileFontStyle={mobileTextFontStyle}
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      padding={padding}
      mobilePadding={mobilePadding}
      onClick={onClick}
      width={width}
      mobileWidth={mobileWidth}
      alignSelf={alignSelf}
      mobileAlignSelf={mobileAlignSelf}
      boxShadow={boxShadow}
      mobileBoxShadow={mobileBoxShadow}
      lineHeight={lineHeight}
      mobileLineHeight={mobileLineHeight}
      disabled={disabled}
      hover={hover}
      mobileHover={mobileHover}
    >
      <ButtonContentUi>
        {iconClassNameBefore && (
          <ButtonIconUi className={iconClassNameBefore} placed={'left'} />
        )}
        {text}
        {iconClassNameAfter && (
          <ButtonIconUi className={iconClassNameAfter} placed={'right'} />
        )}
      </ButtonContentUi>
      {subText && (
        <SubTextUiNew
          color={subTextColor}
          fontFamily={subTextFontFamily}
          fontWeight={subTextFontWeight}
          fontStyle={subTextFontStyle}
          fontSize={subTextFontSize}
          mobileFontSize={mobileSubTextFontSize}
          mobileColor={mobileSubTextColor}
          mobileFontFamily={mobileSubTextFontFamily}
          mobileFontWeight={mobileSubTextFontWeight}
          mobileFontStyle={mobileSubTextFontStyle}
        >
          {subText}
        </SubTextUiNew>
      )}
    </ButtonUi>
  )
}

export default Button
