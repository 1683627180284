import React from 'react'
import CommonLanguageSwitcher from 'common/components/entities/NewLanguageSwitcher'
import { LanguageSwitcherInterface } from 'common/types/entities/LanguageSwitcherInterface'
import { useManagement } from '../../../client/store'
import managementSelectors from '../../../client/store/management/managementSelectors'
import usePage, { selectors } from '../../hooks/usePage'

function NewLanguageSwitcher({
  entity,
}: {
  entity: LanguageSwitcherInterface
}) {
  const locale = usePage(selectors.getLocale)

  const isEditing = useManagement(state =>
    managementSelectors.isEditing(state, entity.id),
  )

  return (
    <CommonLanguageSwitcher
      color={entity.color}
      mobileColor={entity.mobileColor}
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      fontSize={entity.fontSize}
      fontStyle={entity.fontStyle}
      fontFamily={entity.fontFamily}
      fontWeight={entity.fontWeight}
      lineHeight={entity.lineHeight}
      mobileFontSize={entity.mobileFontSize}
      mobileLineHeight={entity.mobileLineHeight}
      mobileFontStyle={entity.mobileFontStyle}
      mobileFontFamily={entity.mobileFontFamily}
      mobileFontWeight={entity.mobileFontWeight}
      redirectPath={entity.redirectPath}
      excludedLanguages={
        entity.excludedLanguages ? entity.excludedLanguages : []
      }
      currentLanguage={locale}
      isEditing={isEditing}
    />
  )
}
export default NewLanguageSwitcher
