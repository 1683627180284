import {
  AutomationRuleSaveInterface,
  AutomationRuleInterface,
  AutomationRulesState,
} from './automationRulesReducer'

export const FETCH_PAGE_AUTOMATION_RULES = 'FETCH_PAGE_AUTOMATION_RULES'
export const SAVE_AUTOMATION_RULE = 'SAVE_AUTOMATION_RULE'
export const SAVE_DELETED_AUTOMATION_RULE = 'SAVE_DELETED_AUTOMATION_RULE'
export const SAVE_AUTOMATION_RULES_STATE = 'SAVE_AUTOMATION_RULES_STATE'

export type FetchPageAutomationRules = {
  type: typeof FETCH_PAGE_AUTOMATION_RULES
  payload: AutomationRuleInterface[]
}

export type SaveAutomationRule = {
  type: typeof SAVE_AUTOMATION_RULE
  payload: AutomationRuleSaveInterface
}

export type SaveAutomationRulesState = {
  type: typeof SAVE_AUTOMATION_RULES_STATE
  payload: AutomationRulesState
}

export type SaveDeletedAutomationRule = {
  type: typeof SAVE_DELETED_AUTOMATION_RULE
  payload: AutomationRuleSaveInterface
}

export type AutomationRulesActionTypes =
  | FetchPageAutomationRules
  | SaveAutomationRule
