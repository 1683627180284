import React, { useContext } from 'react'
import { shallowEqual } from 'react-redux'
import EntityInterface, {
  BaseEntityInterface,
  EntityInnerItemInterface,
} from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { useTypedSelector } from 'publisher/store'
import { getChildrenEntities } from 'publisher/store/page/pageSelectors'
import { CreateEntitiesContext } from '../app'

interface ChildrenEntitiesProps {
  childIds: string[]
}

function ChildrenEntities({ childIds }: ChildrenEntitiesProps) {
  const createEntityElement = useContext(CreateEntitiesContext)
  const entities = useTypedSelector(
    state => getChildrenEntities(state.page, childIds),
    shallowEqual,
  )

  return (
    <>
      {entities.map(
        createEntityElement as unknown as (
          entity:
            | BaseEntityInterface
            | OldEntityInterface
            | EntityInnerItemInterface,
        ) => JSX.Element,
      )}
    </>
  )
}

export default ChildrenEntities
