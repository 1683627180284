import React, { MouseEvent } from 'react'
import { PaddingType } from 'common/types/styleTypes'
import FaqItemArrowUi from './ui/FaqItemArrowUi'
import FaqItemContentUi from './ui/FaqItemContentUi'
import FaqItemTitleUi from './ui/FaqItemTitleUi'
import FaqItemUi from './ui/FaqItemUi'

type FaqItemProps = {
  backgroundColor: string
  mobileBackgroundColor?: string
  fontSize: number
  mobileFontSize: number
  itemBorderRadius: number
  titleColor: string
  mobileTitleColor?: string
  title: string
  fontFamily?: string
  fontStyle?: string
  fontWeight?: string
  expanded: boolean
  itemPadding: PaddingType
  mobileItemPadding: PaddingType
  children: React.ReactElement
  toggleCollapse?: (e: MouseEvent<HTMLDivElement>) => void
  iconClassName: string
  closedIconClassName?: string
  iconColor: string
  mobileIconColor?: string
  boxShadow?: string
  mobileBoxShadow?: string
  showIcons: boolean
  mobileShowIcons: boolean
}

function FaqItem({
  backgroundColor,
  mobileBackgroundColor,
  fontSize,
  mobileFontSize,
  titleColor,
  mobileTitleColor,
  title,
  fontFamily,
  fontStyle,
  fontWeight,
  expanded,
  children,
  itemPadding,
  mobileItemPadding,
  itemBorderRadius,
  toggleCollapse,
  iconClassName,
  closedIconClassName,
  iconColor,
  mobileIconColor,
  boxShadow,
  mobileBoxShadow,
  showIcons = true,
  mobileShowIcons = true,
}: FaqItemProps) {
  const calculateArrowClassName = expanded
    ? iconClassName
    : closedIconClassName
    ? closedIconClassName
    : iconClassName

  const calculateExpanded = expanded
    ? expanded
    : closedIconClassName
    ? true
    : expanded

  return (
    <FaqItemUi
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      padding={itemPadding}
      mobilePadding={mobileItemPadding}
      borderRadius={itemBorderRadius}
      onClick={e => {
        toggleCollapse && toggleCollapse(e)
      }}
      boxShadow={boxShadow}
      mobileBoxShadow={mobileBoxShadow}
    >
      <FaqItemTitleUi
        fontSize={fontSize}
        mobileFontSize={mobileFontSize}
        color={titleColor}
        mobileColor={mobileTitleColor}
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        expanded={expanded}
      >
        {title}
        <FaqItemArrowUi
          showIcons={showIcons}
          mobileShowIcons={mobileShowIcons}
          expanded={calculateExpanded}
          iconColor={iconColor}
          mobileIconColor={mobileIconColor}
          className={calculateArrowClassName}
        />
      </FaqItemTitleUi>
      <FaqItemContentUi expanded={expanded}>{children}</FaqItemContentUi>
    </FaqItemUi>
  )
}

export default FaqItem
