import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import CommonPopup from 'common/components/entities/Popup/RemotePopup'
import { closeRemotePopup } from 'publisher/actionsManagement'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'

class RemotePopup extends PureComponent {
  onCloseHandler = (popupId, closedDays) => () => {
    try {
      this.props.closePopup(popupId, closedDays)
    } catch (e) {
      window.Rollbar.error('Popup close failed', e)
    }
  }

  render() {
    const { entity, overflow, maxHeight, globalSettings } = this.props
    const { showClose, closedDays } = entity.options
    const styles = globalSettings.fontFamily
      ? { ...entity.styles, fontFamily: globalSettings.fontFamily }
      : entity.styles
    return (
      <CommonPopup
        onCloseHandler={this.onCloseHandler(entity.id, closedDays)}
        styles={styles}
        showClose={showClose}
        overflow={overflow}
        maxHeight={maxHeight}
      >
        <BaseNodeChildren childIds={entity.childIds} />
      </CommonPopup>
    )
  }
}

RemotePopup.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      dialogStyles: PropTypes.objectOf(PropTypes.any),
      contentStyles: PropTypes.objectOf(PropTypes.any),
      showClose: PropTypes.bool,
      closedDays: PropTypes.number,
    }),
    childIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    styles: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  closePopup: PropTypes.func.isRequired,
  overflow: PropTypes.string,
  maxHeight: PropTypes.string,
  globalSettings: PropTypes.objectOf(PropTypes.any).isRequired,
}

RemotePopup.defaultProps = {
  showClose: false,
  entity: {
    options: {
      dialogStyles: {},
      contentStyles: {},
      closedDays: 0,
    },
  },
  overflow: 'visible',
  maxHeight: 'calc(100vh - 40px)',
}

const mapDispatchToProps = { closePopup: closeRemotePopup }

export default connect(null, mapDispatchToProps)(RemotePopup)
